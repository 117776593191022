import { toDate } from 'date-fns';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  AddPlus2,
  More,
  NoRFIDs,
  Vehicle as VehicleIcon,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import {
  useDeleteRfidCardMutation,
  useFetchRfidCardsQuery,
} from '../../services/rfid.api';
import { RfidCard } from '../../stores/types/rfidcard.interface';
import { formatDateTime } from '../../utils/Date.Util';
import {
  ButtonSize,
  ButtonType,
  Card,
  ColorType,
  Grid,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Menu,
  MenuItem,
  Pill,
  Skeleton,
  useGlobalModalContext,
} from '../_ui';
import { RfidEditor } from './RfidEditor.component';

export const Rfid = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const { showModal, hideModal } = useGlobalModalContext();
  const RECORDS_PER_PAGE = 25;
  const [
    triggerDeleteRfid,
    {
      isSuccess: deleteRfidSuccess,
      isError: deleteRfidError,
      originalArgs: deleteRfidArgs,
    },
  ] = useDeleteRfidCardMutation();
  const offset = (currentPage - 1) * RECORDS_PER_PAGE;
  const queryParams = {
    includeLastUsedInfo: true,
    limit: RECORDS_PER_PAGE,
    offset,
  };
  const { rfidCards, totalCount, isRfidCardLoading } = useFetchRfidCardsQuery(
    queryParams,
    {
      selectFromResult: (endPoint) => ({
        rfidCards: endPoint.data?.entities ? endPoint.data?.entities : [],
        totalCount: endPoint.data?.totalCount ? endPoint.data?.totalCount : 0,
        isRfidCardLoading: endPoint.isFetching,
      }),
    },
  );

  const onDeleteRfid = (rfid: RfidCard) => {
    triggerDeleteRfid({
      cardNumber: rfid.cardNumber,
      virtualUserId: null,
      vehicleId: null,
      note: null,
    });
  };

  const handleDeleteClick = (rfid: RfidCard) => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title: t('rfid_delete_modal_title'),
      width: '400px',
      height: 'max-content',
      message: t('rfid_delete_modal_message'),
      iconColor: ColorType.NEGATIVE,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          onClick: () => onDeleteRfid(rfid),
        },
      ],
    });
  };

  const handleRegisterRfid = (edit: boolean, rfid?: RfidCard) => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: edit ? t('rfid_edit_modal_title') : t('rfid_register_card'),
      width: '540px',
      height: 'max-content',
      onRenderBody: () => <RfidEditor rfid={rfid} edit={edit} />,
      shouldCloseOnOverlayClick: false,
    });
  };

  const getColumnTitle = () => {
    return [
      {
        key: 'cardNumber',
        title: t('rfid_card_number'),
        component: (row: any) => {
          return (
            <Label
              text={row.cardNumber}
              className=' w-40'
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isRfidCardLoading}
              skeletonHeight='h-5'
            />
          );
        },
      },
      {
        key: 'vehicleName',
        title: t('rfid_assigned_vehicle'),
        class: 'w-40',
        component: (row: any) => {
          if (isRfidCardLoading) {
            return <Skeleton height='h-5' width='w-40' />;
          }
          if (
            !isRfidCardLoading &&
            row?.vehicleName &&
            row.vehicleName !== ''
          ) {
            return (
              <div className='flex flex-row'>
                <Pill label={row.vehicleName} iconLeft={VehicleIcon} />
              </div>
            );
          }
          return (
            <Label
              text={t('rfid_no_vehicle_assigned')}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.GREY6}
              className='h-5 items-center w-40'
            />
          );
        },
      },
      {
        key: 'note',
        title: t('rfid_note'),
        component: (row: any) => {
          if (isRfidCardLoading) {
            return <Skeleton height='h-5' width='w-40' />;
          }
          if (!isRfidCardLoading && row?.note && row?.note !== '') {
            return (
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={row.note}
                className='w-40'
              />
            );
          }
          return (
            <Label
              text='&mdash;'
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.GREY4}
              className='w-40 h-5 items-center'
            />
          );
        },
      },
      {
        key: 'lastUsedLocation',
        title: t('rfid_last_used'),
        component: (row: any) => {
          if (isRfidCardLoading) {
            return <Skeleton height='h-5' width='w-40' />;
          }
          if (
            !isRfidCardLoading &&
            row?.lastUsedLocation &&
            row.lastUsedLocation !== ''
          ) {
            return (
              <div className='text-sm text-black font-normal'>
                <Trans
                  i18nKey='rfid_assigned_vehicle_text'
                  values={{
                    dateTime: formatDateTime(
                      toDate(row.lastUsedTimestampMillis),
                      'LLL dd, HH:mm a',
                    ),
                    location: row.lastUsedLocation,
                  }}
                />
              </div>
            );
          }
          return (
            <Label
              text='&mdash;'
              type={LabelType.LABEL_XS_MEDIUM}
              color={ColorType.GREY4}
              className='h-5 items-center'
            />
          );
        },
      },
      {
        key: 'button',
        title: t(''),
        component: (row: any) => (
          <div className='flex flex-row gap-[16px] justify-end pr-2'>
            <Menu
              placement='bottom-end'
              icon={More}
              iconAlt={t('icon_alt_more_options')}
            >
              <MenuItem
                label={t('rfid_edit_modal_title')}
                onClick={() => handleRegisterRfid(true, row)}
              />
              <MenuItem
                label={t('rfid_delete_button')}
                labelType={LabelType.BODY3}
                labelColor={ColorType.NEGATIVE}
                onClick={() => handleDeleteClick(row)}
              />
            </Menu>
          </div>
        ),
      },
    ];
  };

  const renderEmptyRfid = () => {
    if (!isRfidCardLoading && !rfidCards?.length) {
      return (
        <div className='flex flex-col gap-1 items-center justify-center py-4 h-52'>
          <Icon className='grey5' src={NoRFIDs} size={IconSize.SIZE_48x48} />
          <Label
            text={t('rfid_empty_message')}
            type={LabelType.H4}
            color={ColorType.GREY5}
          />
          <Label
            text={t('rfid_empty_desc')}
            type={LabelType.BODY3}
            color={ColorType.GREY5}
          />
        </div>
      );
    }
  };

  const renderRfidGrid = () => {
    if (isRfidCardLoading || rfidCards?.length > 0) {
      return (
        <div className='pt-4'>
          <Grid
            pageIndex={currentPage}
            loadPage={(page) => setCurrentPage(page)}
            local={false}
            columns={getColumnTitle()}
            data={rfidCards}
            primaryKey='cardNumber'
            totalPage={Math.ceil(totalCount / RECORDS_PER_PAGE)}
            rowCount={RECORDS_PER_PAGE}
            isLoading={isRfidCardLoading}
          />
        </div>
      );
    }
  };

  const renderCardTitle = () => {
    return (
      <div className='flex flex-row items-baseline gap-2'>
        <Label type={LabelType.H4} color={ColorType.BLACK} text={t('rfid')} />
        <div className='text-sm font-normal font-inter text-grey5'>
          {(totalCount || 0) < RECORDS_PER_PAGE ? (
            <Trans
              i18nKey='rfid_showing_number_10'
              values={{
                total: rfidCards?.length || 0,
              }}
            />
          ) : (
            <Trans
              i18nKey='rfid_showing_number'
              values={{
                start: offset + 1,
                end:
                  currentPage * RECORDS_PER_PAGE > (totalCount || 0)
                    ? totalCount || 0
                    : currentPage * RECORDS_PER_PAGE,
                total: totalCount || 0,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Card
        OnRenderTitle={renderCardTitle}
        headerButtonIcon={AddPlus2}
        headerButtonLabel={t('rfid_register_card')}
        headerButtonOnClick={() => handleRegisterRfid(false)}
      >
        {renderEmptyRfid()}
        {renderRfidGrid()}
      </Card>
    </div>
  );
};
