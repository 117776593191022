import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../hooks/useCompany';
import { useValidateDisplayIdAndOCPPMutation } from '../../../services/charger.api';
import { Charger } from '../../../stores/types';
import { DisplayIdAndOCCPValidationRequest } from '../../../stores/types/chargers.interface';
import { InternalErrorCode } from '../../../stores/types/error.interface';
import { FormInput } from '../../_ui';
import { InputType } from '../../_ui/Input.component';
import { handleValidateId } from '../utils';

interface IProps {
  keyId: string;
  defaultValue: string | undefined;
  charger: Charger;
  setisValidDisplayIdOCPP: any;
  handleInputChange: any;
  maxLen?: string | undefined;
  inputType?: InputType | undefined;
}
const InputDisplayOCPP = ({
  keyId,
  defaultValue,
  charger,
  handleInputChange,
  setisValidDisplayIdOCPP,
  maxLen,
  inputType,
}: IProps) => {
  const { userScope } = useCompany();
  const { t } = useTranslation();

  const [triggerDisplayValidate, validateResponse] =
    useValidateDisplayIdAndOCPPMutation();

  const handleDisplayValidation = (val: string) => {
    if (val) {
      const req: DisplayIdAndOCCPValidationRequest = {
        id: charger.id,
        chargerModelId: charger.chargerModelId,
        ocppId: keyId === 'ocppId' ? val : charger.ocppId,
        priceModelId: charger.priceModelId,
        displayId: keyId === 'displayId' ? val : charger.name,
        locationId: charger?.location?.id,
      };
      triggerDisplayValidate({ request: req, scope: userScope! });
    }
  };

  const renderErrorLabel = () => {
    if (validateResponse.isError) {
      const errorObj: any = validateResponse?.error;
      if (errorObj?.data?.code === InternalErrorCode.ERR_CS_0003) {
        return keyId === 'ocppId'
          ? t('invalid_ocpp_id')
          : t('invalid_display_id');
      }
      if (errorObj?.data?.code === InternalErrorCode.ERR_CS_0034) {
        return keyId === 'ocppId'
          ? t('duplicate_ocpp_id')
          : t('duplicate_display_id');
      }
    }
  };

  useEffect(() => {
    const isValid = !validateResponse.isError;
    const keyToUpdate = keyId === 'ocppId' ? 'ocppId' : 'displayId';
    setisValidDisplayIdOCPP((fields: any) => ({
      ...fields,
      [keyToUpdate]: isValid,
    }));
  }, [validateResponse]);

  return (
    <FormInput
      defaultValue={defaultValue}
      onChange={(event: any) => {
        handleDisplayValidation(event.target.value);
        handleInputChange(event.target.value);
      }}
      width='100% '
      height='36px'
      maxLen={maxLen}
      inputType={inputType}
      validaitonQueryResult={validateResponse.isError}
      showErrorIcon
      errorLabel={renderErrorLabel()}
      onValidation={handleValidateId}
    />
  );
};

export default InputDisplayOCPP;
