// src/hooks/useSurvey.ts

import { usePostHog } from 'posthog-js/react';

interface SurveyResponse {
  triggerSurveyByEvent: (surveyName: string) => void;
}

export const useSurvey = (): SurveyResponse => {
  const posthog = usePostHog();

  /**
   * Shows a survey with the given name if the user is eligible.
   * @param surveyName The name of the survey to show. Please don't use surveyId, different envs has different survey ids.
   */
  const triggerSurveyByEvent = (surveyName: string) => {
    posthog.getSurveys((surveys) => {
      const survey = surveys.find(
        (s) => s.name.toLowerCase() === surveyName.toLowerCase(),
      );
      if (survey) {
        posthog.capture(surveyName);
      }
    }, true);
  };

  return { triggerSurveyByEvent };
};
