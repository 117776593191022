import { Trans, useTranslation } from 'react-i18next';
import { Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { TEXT } from '../../../constant/Text.constant';
import { Charger } from '../../../stores/types';
import {
  ColorType,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  Pill,
  RadioGroup,
  Switch,
} from '../../_ui';
import { validateEmail } from '../../_ui/modal/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';

type ChargerAutoStartProps = {
  charger: Charger;
  editEnabled: boolean;
  isLoading: boolean;
  updateChargerDetail: any;
  setUpdateChargerDetail: any;
};

export const ChargerAutoStart = ({
  charger,
  editEnabled,
  isLoading,
  updateChargerDetail,
  setUpdateChargerDetail,
}: ChargerAutoStartProps) => {
  const { t } = useTranslation();
  const { SIMULATED } = TEXT;

  const isAutoStartConfigSimulated =
    updateChargerDetail?.autoStartConfig?.type === SIMULATED;

  const autoStartTypeData = () => {
    const isCompany = updateChargerDetail.autoStartConfig?.email === undefined;
    const data = [
      {
        id: 'company',
        label: t('company'),
        selected: isCompany,
      },
      {
        id: 'driver',
        label: t('driver'),
        selected: !isCompany,
      },
    ];
    return data;
  };

  const handleAutoStart = (event: any) => {
    if (event[0]?.selected) {
      setUpdateChargerDetail({
        ...updateChargerDetail,
        autoStartConfig: {
          type: SIMULATED,
        },
      });
    }
    if (event[1]?.selected) {
      setUpdateChargerDetail({
        ...updateChargerDetail,
        autoStartConfig: {
          type: SIMULATED,
          email: '',
        },
      });
    }
  };

  const handleEmailValidation = (val: any) => {
    if (val) {
      return validateEmail(val);
    }
    return true;
  };

  const handleAssociatedEmail = (value: any) => {
    setUpdateChargerDetail({
      ...updateChargerDetail,
      autoStartConfig: {
        type: SIMULATED,
        email: value,
      },
    });
  };

  return (
    <div className={`flex flex-col gap-0 pt-4 ${editEnabled ? 'pb-3' : ''}`}>
      <div className='flex flex-row gap-1 items-start'>
        <Label
          text={t('charger_auto_start')}
          type={LabelType.LABEL_M}
          color={ColorType.GREY5}
          style={{ minHeight: '40px' }}
        />
        <Tooltip>
          <TooltipTrigger>
            <Icon src={Info} size={IconSize.SIZE_20x20} />
          </TooltipTrigger>
          <TooltipContent>
            <Trans
              i18nKey='chargers_auto_start_hint'
              components={{
                br: <br />,
              }}
            />
          </TooltipContent>
        </Tooltip>
      </div>
      <div className='flex flex-row items-start min-h-[40px]'>
        <Label
          text={t('charger_auto_start_status')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='min-w-[148px] max-w-[148px] mr-2.5'
        />
        {!editEnabled && (
          <Label
            text={
              charger?.autoStartConfig
                ? t('charger_auto_start_status_true')
                : t('charger_auto_start_status_false')
            }
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={isLoading}
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        )}
        {editEnabled && (
          <Switch
            value={isAutoStartConfigSimulated}
            enableLabel={t('enabled')}
            disableLabel={t('disabled')}
            onChange={(value: boolean) => {
              setUpdateChargerDetail({
                ...updateChargerDetail,
                autoStartConfig: {
                  type: value ? SIMULATED : 'DISABLED',
                },
              });
            }}
            dataTestId='charger-auto-enable-switch'
          />
        )}
      </div>
      {editEnabled && isAutoStartConfigSimulated && (
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_start_session')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <div className='flex flex-col gap-0.5'>
            <RadioGroup
              direction={GroupDirection.Vertical}
              defaultItems={autoStartTypeData()}
              onChange={(event: any) => handleAutoStart(event)}
              itemHeight='30px'
            />
          </div>
        </div>
      )}
      {!editEnabled && isAutoStartConfigSimulated && (
        <>
          <div className='flex flex-row items-start min-h-[40px]'>
            <Label
              text={t('charger_start_session')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex-1'>
              <Label
                text={`${
                  !charger?.autoStartConfig?.email ? t('company') : t('driver')
                }`}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={isLoading}
                skeletonWidth='w-52'
                skeletonHeight='h-5'
              />
            </div>
          </div>
          {!!charger?.autoStartConfig?.email && (
            <div className='flex flex-row items-start min-h-[40px]'>
              <Label
                text={t('charger_auto_start_associated_email')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
                className='min-w-[148px] max-w-[148px] mr-2.5'
              />
              <div className='flex-1'>
                <Pill
                  label={charger?.autoStartConfig?.email}
                  labelColor={ColorType.GREY6}
                  bgColor={ColorType.GREY2}
                  isLoading={isLoading}
                />
              </div>
            </div>
          )}
        </>
      )}
      {editEnabled &&
        updateChargerDetail.autoStartConfig?.email !== undefined && (
          <div className='flex flex-row pt-4'>
            <Label
              text={t('charger_auto_start_associated_email')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex-1'>
              <FormInput
                showErrorIcon
                onValidation={handleEmailValidation}
                width='100%'
                height='36px'
                placeholder={t('auto_start_user_email')}
                errorLabel={t('chargers_email_validation_message')}
                onChange={(event: any) =>
                  handleAssociatedEmail(event.target.value)
                }
                defaultValue={updateChargerDetail.autoStartConfig?.email || ''}
              />
            </div>
          </div>
        )}
    </div>
  );
};
