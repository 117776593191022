import { License } from '../stores/types';
import {
  LicenseListRequest,
  LicenseListResponse,
} from '../stores/types/licenses.interface';
import { Api, API_KEYS } from './base.api';
import { providesListTag, SCOPE_TYPE } from './utils';

export const licenseApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchLicenseByLicenseKey: builder.query<
      License,
      { scope?: SCOPE_TYPE; companyId?: string; licenseKey?: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}/${payload.licenseKey}`,
        params: { scope: payload.scope, companyId: payload.companyId },
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.LICENSE, id: result?.licenseKey },
      ],
    }),
    fetchLicenseList: builder.query<LicenseListResponse, LicenseListRequest>({
      query: (param) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}`,
        params: param,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        providesListTag(
          result?.entities.map((license) => ({ id: license.licenseKey })),
          API_KEYS.LICENSE,
        ),
    }),
  }),
});

export const {
  useFetchLicenseByLicenseKeyQuery,
  useFetchLicenseListQuery,
  useLazyFetchLicenseListQuery,
} = licenseApi;
