import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import {
  ColorType,
  Icon,
  Label,
  LabelType,
  Snackbar,
  Switch,
  getHexColorByType,
} from '..';
import {
  CheckMark,
  ExitDeleteRemoveClose,
  Settings,
} from '../../../assets/icons';
import { useCompany } from '../../../hooks/useCompany';
import { Location } from '../../../stores/types';

import { useFetchLocationsV3Query } from '../../../services/location.api';
import {
  useEditNotificationMutation,
  useFetchNotificationsQuery,
} from '../../../services/notification.api';
import { getCurrentTheme } from '../../../stores/selectors/theme.selector';
import {
  Notification,
  NotificationCheckboxData,
} from '../../../stores/types/notification.interface';
import { Button, ButtonSize, ButtonType } from '../Button.component';
import { Form } from '../Form.component';
import { AlertPosition, AlertType } from '../snack-bar/Snack-Bar.component';
import { MODAL_TYPES, useGlobalModalContext } from './GlobalModal.component';
import { MODAL_LAYER_INDEX } from './utils';

Modal.setAppElement('#root');

export const NotificationSettingModal = () => {
  const theme = useSelector(getCurrentTheme);
  const { t } = useTranslation();
  const { hideModal, showModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const {
    title = t('notification_settings_label'),
    width = '540px',
    height = 'max-content',
    notification,
    data = [],
  } = modalProps;
  const { userScope } = useCompany();
  const { data: checkedNotification } = useFetchNotificationsQuery(userScope!);
  const [triggerNotificationEdit, notificationEditQueryResult] =
    useEditNotificationMutation();

  const {
    companies,
    companyScope,
    chargersCount: totalChargers,
    hasMultiCompanies: isMultipleCompany,
  } = useCompany();

  const { data: locations } = useFetchLocationsV3Query({
    scope: companyScope,
  });

  let generatedData: any = data;
  const [toggleValue, setToggleValue] = useState<boolean>();
  const [updateNotification, setUpdateNotification] = useState(notification);

  useEffect(() => {
    if (notification) {
      setToggleValue(notification[0]?.enabled);
    }
  }, [notification]);

  const handleClose = () => {
    hideModal();
  };

  const showToast = (message: string) => {
    Snackbar.show({
      message,
      position: AlertPosition.BOTTOM_LEFT,
      alertType: AlertType.DEFAULT,
      duration: 5000,
      messageLabelType: LabelType.LABEL_S_MEDIUM,
      messageLabelColor: ColorType.BLACK,
      icon: CheckMark,
      iconColor: getHexColorByType(ColorType.POSITIVE),
    });
  };

  const handleSave = () => {
    if (notification) {
      triggerNotificationEdit({
        notification: { ...notification[0], enabled: toggleValue },
        scope: userScope!,
      });
    }
  };

  const createCompanyNodes = () => {
    const nodes: NotificationCheckboxData[] = [];
    companies?.forEach((company) => {
      // only create nodes for companies with > 0 locations
      if ((company.noOfLocations ?? 0) > 0) {
        nodes.push({
          id: company.id,
          label: company.name,
          selected:
            checkedNotification &&
            checkedNotification[0].resources.companies?.some(
              (c) => c.id === company.id,
            ),
          isOpen: false,
          children: [],
          noOfLocations: company.noOfLocations,
          noOfChargers: company.noOfChargers,
        });
      }
    });
    return nodes;
  };

  // Following method to mark charger as selected is called only when user has access to single company
  // If user selected all locations of a company--> BE returns company id
  // If user selected all chargers of a location --> BE returns location id
  // If user selected some chargers --> BE returns charger id(s)
  const handleChargerNodeSelection = (location: Location, charger: any) => {
    if (checkedNotification) {
      if (
        (companies &&
          checkedNotification[0].resources.companies?.some(
            (company) => company.id === companies[0].id,
          )) ||
        checkedNotification[0].resources.locations?.some(
          (l) => l.id === location.id,
        ) ||
        checkedNotification[0].resources.chargers?.some(
          (c) => c.id === charger.id,
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const createChargerNodes = (location: Location) => {
    const nodes: NotificationCheckboxData[] = [];
    location?.chargers?.forEach((charger: any) => {
      nodes.push({
        id: charger.id,
        label: charger.displayName,
        selected: handleChargerNodeSelection(location, charger),
        isOpen: false,
      });
    });
    return nodes;
  };

  // Following method to mark location as selected is called only when user has access to single company
  // If user selected all locations of a company--> BE returns company id
  // If user selected some location  --> BE returns location id(s)
  const handleLocationNodeSelection = (location: Location) => {
    if (checkedNotification) {
      if (
        (companies &&
          checkedNotification[0].resources.companies?.some(
            (company) => company.id === companies[0].id,
          )) ||
        checkedNotification[0].resources.locations?.some(
          (l) => l.id === location.id,
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const createLocationNodes = () => {
    const nodes: NotificationCheckboxData[] = [];
    locations?.entities.forEach((location: any) => {
      nodes.push({
        id: location.id,
        label: location.name,
        selected: handleLocationNodeSelection(location),
        isOpen: false,
        children:
          location.chargers && location.chargers?.length > 0
            ? createChargerNodes(location)
            : [],
        noOfChargers: location.chargers?.length,
      });
    });
    return nodes;
  };

  const handleSelectChargerClick = () => {
    if (generatedData.length === 0) {
      if (isMultipleCompany) {
        generatedData = createCompanyNodes();
      } else {
        generatedData = createLocationNodes();
      }
    }
    showModal(MODAL_TYPES.NOTIFICATION_CHARGER_MODAL, {
      notification: updateNotification,
      generatedData,
      isMultiCompany: isMultipleCompany,
      totalChargers,
    });
  };

  const renderHeader = () => {
    return (
      <div className='flex items-center justify-center sticky top-0 h-16'>
        <Icon
          src={ExitDeleteRemoveClose}
          className='w-[28px] h-[28px] hover:bg-grey2 rounded-full flex items-center justify-center absolute left-0 cursor-pointer'
          onClick={handleClose}
        />
        <Label text={title} type={LabelType.H5} color={ColorType.BLACK} />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className='block'>
        <div className='flex flex-row gap-12 items-center justify-between'>
          <div className='flex flex-row items-center'>
            <Label
              type={LabelType.LABEL_S_MEDIUM}
              text={t('notification_modal_message')}
              style={{
                color: '202223',
              }}
            />
          </div>
          <div className='flex'>
            <Switch
              value={toggleValue}
              enableLabel={t('notification_modal_toggle_on')}
              disableLabel={t('notification_modal_toggle_off')}
              onChange={(checked: boolean) => {
                setToggleValue(checked);
                const newNotification = (updateNotification || []).map(
                  (notif: Notification) => {
                    return {
                      ...notif,
                      enabled: checked,
                    };
                  },
                );
                setUpdateNotification(newNotification);
              }}
              dataTestId='notificationSwitch'
            />
          </div>
        </div>
        <div className='flex flex-row items-center block justify-between pt-1.5 pb-6 pl-2'>
          {toggleValue && (
            <Label
              text={t('notification_modal_charger')}
              svgIcon={Settings}
              svgColor={theme.navigationSelectedColor}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.BLACK}
              onClick={handleSelectChargerClick}
              dataTestId='selectChargersButton'
            />
          )}
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='h-12 flex mb-4'>
        <Button
          dataTestId='saveNotification'
          label={t('save')}
          type={ButtonType.PRIMARY}
          size={ButtonSize.MEDIUM_FULL}
          isSumbit
        />
      </div>
    );
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          top: '22.5%',
          left: '50%',
          width,
          height,
          transform: 'translate(-50%, -50%)',
          padding: 0,
          borderRadius: 10,
          overflow: 'visible',
        },
        overlay: {
          zIndex: MODAL_LAYER_INDEX,
          backgroundColor: 'rgba(32,34,35,0.5)',
        },
      }}
    >
      <Form
        onSubmit={handleSave}
        queryResult={notificationEditQueryResult}
        onQuerySuccess={() => {
          handleClose();
          return showToast(t('notification_modal_save_toast'));
        }}
        onQueryFailed={() => {
          handleClose();
          return showToast(t('notification_modal_save_toast'));
        }}
      >
        <div className='flex flex-col px-4'>
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </Form>
    </Modal>
  );
};
