import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchTerminalsQuery } from '../../../services/payouts.api';
import { Charger } from '../../../stores/types';
import { TerminalObj } from '../../../stores/types/payter.interface';
import ConnectedChargers from '../../Payouts/PayoutTerminal/ConnectedChargers.component';
import { ColorType, Dropdown, LabelType } from '../../_ui';

interface IProps {
  charger: Charger;
  handleInputChange: (key: string, val: string) => void;
}

const EditTerminal = ({ charger, handleInputChange }: IProps) => {
  const { t } = useTranslation();
  const [selectedTerminal, setSelectedTerminal] = useState(
    charger.paymentTerminalSerialNumber,
  );

  const handleSelectTerminal = (value: any) => {
    setSelectedTerminal(value.id);
    handleInputChange('paymentTerminalSerialNumber', value.id);
  };

  const { data: terminalData, isLoading: isTerminalLoading } =
    useFetchTerminalsQuery({});

  const renderTerminalChargerInfo = (terminal: TerminalObj) => {
    return (
      <ConnectedChargers
        connectedChargerIds={terminal.associatedChargers}
        isTerminalDataLoading={isTerminalLoading}
        isChargerDetails
      />
    );
  };

  const terminalDropdown = useMemo(() => {
    const terminals = (terminalData?.entities || []).map(
      (terminal: TerminalObj) => {
        return {
          id: terminal.serialNumber,
          label: `${terminal.serialNumber} (${terminal.provider})`,
          selected: terminal.serialNumber === selectedTerminal,
          secondLabelContent: () => renderTerminalChargerInfo(terminal),
        };
      },
    );
    return terminals;
  }, [terminalData, selectedTerminal]);

  return (
    <div className='flex-1'>
      <Dropdown
        placement='bottom-end'
        placeholder={t('select_terminal')}
        placeholderLabelType={LabelType.BODY3_G4}
        items={terminalDropdown}
        headerWidth='100%'
        onItemClick={handleSelectTerminal}
        showFooter={terminalDropdown?.length === 0}
        contentDivHeight={300}
        labelType={LabelType.BODY3_G4}
        labelTypeHighlighted={LabelType.DROPDOWN_ITEM_SELECTED}
        chevdownIconHighlightColor={ColorType.GREY6}
        headerHighLightClassName='bg-grey1 hover:bg-grey2 border-grey3 rounded h-[36px] pr-2'
        headerClassName='bg-grey1 hover:bg-grey2 border-grey3 rounded pr-2'
        headerHeight='36px'
      />
    </div>
  );
};

export default EditTerminal;
