import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Info, hidePassword, showPassword } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { useCompany } from '../../../hooks/useCompany';
import { useLazyFetchPricingModelsQuery } from '../../../services/pricing.api';
import { useFetchChargerStaticDataQuery } from '../../../services/provision';
import { Charger } from '../../../stores/types';
import { Connectivity } from '../../../stores/types/chargers.interface';
import { Price } from '../../../stores/types/price.interface';
import {
  CheckBox,
  CheckBoxData,
  ColorType,
  Dropdown,
  FormInput,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  RadioGroup,
} from '../../_ui';
import { InputType } from '../../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';

type ChargerSettingProps = {
  charger: Charger;
  editEnabled: boolean;
  isLoading: boolean;
  handleInputChange: any;
  updateChargerDetail: any;
  updateChargerQueryResult: any;
  filteredCircuits: any;
};

export const ChargerSetting = ({
  charger,
  editEnabled,
  isLoading,
  handleInputChange,
  updateChargerDetail,
  updateChargerQueryResult,
  filteredCircuits,
}: ChargerSettingProps) => {
  const { t } = useTranslation();
  const { companyId, userScope } = useCompany();
  const SIM_CARD_NUMBER_LEN = 22;
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const { networkProvider } = useFetchChargerStaticDataQuery(undefined, {
    selectFromResult: (endpoint) => ({
      networkProvider: endpoint.data ? endpoint.data.simCardProviders : [],
    }),
  });

  const [triggerFetchingPricingModels, { data: pricing }] =
    useLazyFetchPricingModelsQuery();

  const networkProviderDropdown = useMemo(() => {
    return networkProvider.map((network: any, index: number) => ({
      id: index,
      label: network,
      selected: network === charger?.deploymentInfo?.simCardProvider,
    }));
  }, [networkProvider, charger?.deploymentInfo?.simCardProvider]);

  const pricingDropdown = useMemo(() => {
    return pricing?.map((pricingObj: Price) => ({
      id: pricingObj.id,
      label: pricingObj.displayName,
      selected: pricingObj.id === charger?.priceModelId,
    }));
  }, [pricing, charger?.priceModelId]);

  const connectionTypeData = () => {
    return [
      {
        id: Connectivity.SIM_CARD,
        label: t('chargers_sim_card'),
        selected:
          updateChargerDetail.deploymentInfo?.connectivityType ===
          Connectivity.SIM_CARD,
        disabled: updateChargerQueryResult.isLoading,
      },
      {
        id: Connectivity.WIFI,
        label: t('chargers_wifi'),
        selected:
          updateChargerDetail.deploymentInfo?.connectivityType ===
          Connectivity.WIFI,
        disabled: updateChargerQueryResult.isLoading,
      },
      {
        id: Connectivity.ETHERNET,
        label: t('chargers_ethernet'),
        selected:
          updateChargerDetail.deploymentInfo?.connectivityType ===
          Connectivity.ETHERNET,
        disabled: updateChargerQueryResult.isLoading,
      },
    ];
  };

  const getAccessText = () => {
    const text =
      updateChargerDetail?.autoStartConfig?.type === 'SIMULATED'
        ? t('charger_auto_start_enabled')
        : t((charger?.access || '').toLowerCase());
    return text;
  };

  const handleConnectionType = (items: CheckBoxData[]) => {
    const selectedConnection = items.find((item) => item.selected === true);
    handleInputChange('deploymentInfo', {
      ...updateChargerDetail.deploymentInfo,
      connectivityType: selectedConnection?.id as Connectivity,
    });
  };

  const displayConnectivityName = (displayId: Connectivity) => {
    if (displayId === Connectivity.SIM_CARD) {
      return t('chargers_sim_card');
    }
    if (displayId === Connectivity.WIFI) {
      return t('chargers_wifi');
    }
    if (displayId === Connectivity.ETHERNET) {
      return t('chargers_ethernet');
    }
    return '';
  };

  const renderSimCardConnectivity = () => {
    if (
      !editEnabled &&
      updateChargerDetail.deploymentInfo?.connectivityType ===
        Connectivity.SIM_CARD
    ) {
      return (
        <div className='flex flex-row gap-2 items-center h-10'>
          <div className='flex flex-col gap-0.5'>
            <Label
              text={`${t('charger_network')}:`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
            <Label
              text={`${t('chargers_sim_card')}:`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
          <div className='flex flex-col gap-0.5 items-center'>
            <Label
              text={updateChargerDetail?.deploymentInfo?.simCardProvider}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
              className=' w-[140px] truncate min-h-[20px]'
            />
            <Label
              text={updateChargerDetail?.deploymentInfo?.simCardId}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
              className=' w-[140px] truncate'
            />
          </div>
        </div>
      );
    }
    if (
      editEnabled &&
      updateChargerDetail?.deploymentInfo?.connectivityType ===
        Connectivity.SIM_CARD
    ) {
      return (
        <div className={`flex flex-col ${editEnabled ? 'gap-1' : 'gap-0'}`}>
          <div className='flex flex-row items-start min-h-[40px]'>
            <Label
              text={t('chargers_network_provider')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex-1'>
              <Dropdown
                items={networkProviderDropdown}
                onItemClick={(selectedItem: any) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    simCardProvider: selectedItem.label,
                  });
                }}
                headerWidth='100%'
                labelType={LabelType.BODY3_G4}
                labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
                chevdownIconHighlightColor={ColorType.GREY6}
                headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
                headerClassName='bg-grey1 border-grey3 rounded pr-2'
                headerHeight='36px'
              />
            </div>
          </div>
          <div className='flex flex-row items-start min-h-[40px]'>
            <Label
              text={t('chargers_sim_card_number')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex-1'>
              <FormInput
                defaultValue={updateChargerDetail?.deploymentInfo?.simCardId}
                onChange={(event: any) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    simCardId: event.target.value,
                  });
                }}
                inputType={InputType.NUMBER}
                width='100%'
                height='36px'
                showErrorIcon
                maxLen={String(SIM_CARD_NUMBER_LEN)}
              />
            </div>
          </div>
        </div>
      );
    }
  };
  const renderWifiConnectivity = () => {
    if (
      !editEnabled &&
      updateChargerDetail.deploymentInfo?.connectivityType === Connectivity.WIFI
    ) {
      return (
        <div className='flex flex-row gap-2 items-start min-h-[40px]'>
          <div className='flex flex-col gap-0.5'>
            <Label
              text={`${t('chargers_wifi_network_name_placeholder')}:`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
            <Label
              text={`${t('chargers_wifi_network_password_placeholder')}:`}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
          <div className='flex flex-col gap-0.5'>
            <Label
              text={updateChargerDetail?.deploymentInfo?.wifiName}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
              className=' w-[140px] truncate'
            />
            <div className='flex flex-row gap-1 items-start break-all inline-block'>
              <Label
                text={
                  !passwordVisibility
                    ? t('encrypted_password')
                    : updateChargerDetail?.deploymentInfo?.wifiPassword
                }
                type={LabelType.BODY3}
                color={ColorType.GREY6}
                className='flex'
              />
              <Icon
                src={!passwordVisibility ? showPassword : hidePassword}
                size={IconSize.SIZE_16x16}
                onClick={() => setPasswordVisibility(!passwordVisibility)}
                className='flex pt-[5px]'
              />
            </div>
          </div>
        </div>
      );
    }
    if (
      editEnabled &&
      updateChargerDetail?.deploymentInfo?.connectivityType ===
        Connectivity.WIFI
    ) {
      return (
        <div className='flex flex-col gap-0'>
          <div className='flex flex-row items-start min-h-[40px]'>
            <Label
              text={t('charger_wifi_name')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex-1'>
              <FormInput
                defaultValue={updateChargerDetail?.deploymentInfo?.wifiName}
                onChange={(event: any) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    wifiName: event.target.value,
                  });
                }}
                inputType={InputType.TEXT}
                width='100%'
                height='36px'
              />
            </div>
          </div>
          <div className='flex flex-row items-start min-h-[40px]'>
            <Label
              text={t('charger_wifi_password')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex-1'>
              <FormInput
                defaultValue={updateChargerDetail?.deploymentInfo?.wifiPassword}
                onChange={(event: any) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    wifiPassword: event.target.value,
                  });
                }}
                inputType={InputType.TEXT}
                width='100%'
                height='36px'
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const renderPricing = () => {
    const isAutoStartComapnyEnabled =
      updateChargerDetail.autoStartConfig?.type === 'SIMULATED' &&
      updateChargerDetail.autoStartConfig?.email === undefined;
    if (editEnabled) {
      return (
        <div className='flex-1'>
          {isAutoStartComapnyEnabled ? (
            <Label
              text={t('charger_auto_start_company_enabled')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              className='w-auto py-2.5'
            />
          ) : (
            <Dropdown
              headerWidth='100%'
              items={pricingDropdown}
              onItemClick={(selectedItem: any) =>
                handleInputChange('priceModelId', selectedItem.id)
              }
              labelType={LabelType.BODY3_G4}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[36px] pr-2'
              headerClassName='bg-grey1 border-grey3 rounded pr-2'
              headerHeight='36px'
            />
          )}
        </div>
      );
    }
    return (
      <div className='flex flex-col'>
        <Label
          text={
            isAutoStartComapnyEnabled
              ? t('charger_auto_start_company_enabled')
              : charger?.priceModelDisplayName
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isLoading}
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
      </div>
    );
  };

  useEffect(() => {
    if (companyId) {
      triggerFetchingPricingModels({ companyId: companyId, scope: userScope! });
    }
  }, [companyId, userScope]);

  return (
    <div className='flex flex-col gap-0 pt-4'>
      <div className='flex flex-row'>
        <Label
          text={t('charger_settings')}
          type={LabelType.LABEL_M}
          color={ColorType.GREY5}
          style={{ minHeight: '40px' }}
        />
        <Label text='' type={LabelType.BODY3} color={ColorType.BLACK} />
      </div>
      <div className={`flex flex-col ${editEnabled ? 'gap-1' : 'gap-0'}`}>
        {!editEnabled && (
          <div className='flex flex-row items-start min-h-[40px]'>
            <Label
              text={t('network_connectivity')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex flex-col gap-0.5'>
              <Label
                text={displayConnectivityName(
                  charger?.deploymentInfo?.connectivityType as Connectivity,
                )}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                isLoading={isLoading}
                skeletonWidth='w-52'
                skeletonHeight='h-5'
              />
              {!isLoading && renderSimCardConnectivity()}
              {!isLoading && renderWifiConnectivity()}
            </div>
          </div>
        )}
        {editEnabled && (
          <div className='flex flex-row flex-start'>
            <Label
              text={t('chargers_connection_type')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
            <div className='flex flex-col gap-0.5'>
              <RadioGroup
                direction={GroupDirection.Vertical}
                defaultItems={connectionTypeData()}
                onChange={handleConnectionType}
                itemHeight='30px'
              />
            </div>
          </div>
        )}
        {editEnabled && renderSimCardConnectivity()}
        {editEnabled && renderWifiConnectivity()}
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_pricing')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {renderPricing()}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_access')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <Label
            text={getAccessText()}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={isLoading}
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <div className='flex flex-row gap-1 min-w-[148px] max-w-[148px] mr-2.5'>
            <Label
              text={t('chargers_map_visibility')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
            />
            <Tooltip>
              <TooltipTrigger>
                <Icon src={Info} size={IconSize.SIZE_20x20} />
              </TooltipTrigger>
              <TooltipContent>
                <Label
                  text={t('chargers_map_visibility_hint')}
                  type={LabelType.BODY3}
                  color={ColorType.WHITE}
                />
              </TooltipContent>
            </Tooltip>
          </div>
          {!editEnabled && (
            <Label
              text={
                charger?.hiddenOnMap
                  ? t('charger_map_visibility_false')
                  : t('chargers_visibility_user_access')
              }
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isLoading}
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <div className='flex-1'>
              <CheckBox
                label={t('chargers_visibility_user_access')}
                key='visible'
                index={0}
                selected={!updateChargerDetail.hiddenOnMap}
                onChange={(selectedCheckbox: boolean) =>
                  handleInputChange('hiddenOnMap', !selectedCheckbox)
                }
                isDisabled={updateChargerQueryResult.isLoading}
                dataTestId='charger-map-visiblilty-button'
              />
            </div>
          )}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_power_managed')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <Label
            text={
              charger?.isPowerManaged ? t('chargers_yes') : t('chargers_no')
            }
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={isLoading}
            skeletonWidth='w-52'
            skeletonHeight='h-5'
            className='mr-2'
          />
          {!charger?.isPowerManaged &&
            filteredCircuits &&
            filteredCircuits?.length >= 1 && (
              <Tooltip>
                <TooltipTrigger>
                  <Icon
                    src={Info}
                    size={IconSize.SIZE_20x20}
                    color={ColorType.WARNING}
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <span
                    className='text-sm text-white font-medium'
                    style={{ fontFamily: 'Inter' }}
                  >
                    <Trans
                      i18nKey='charger_detail_pm_soft_warning'
                      components={{
                        br: <br />,
                      }}
                    />
                  </span>
                </TooltipContent>
              </Tooltip>
            )}
        </div>
      </div>
    </div>
  );
};
