// React & redux
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

// Hooks
import { useTranslation } from 'react-i18next';

// Utils
import { formatInTimeZone } from 'date-fns-tz';
import { convertToLocaleCurrency } from '../../utils/Currency.Util';
import {
  convertDateToString,
  convertMilliSecondToDate,
  getLastMonth,
  getLocale,
} from '../../utils/Date.Util';
import {
  generateChargerStatusHistory,
  generateFullAddress,
  getSortedRecentSessions,
} from './utils';

// Components
import {
  Button,
  ButtonType,
  Card,
  ColorType,
  CustomDatePicker,
  Dropdown,
  DropdownType,
  Grid,
  Icon,
  Label,
  LabelType,
  Pill,
  Skeleton,
} from '../_ui';
import { ButtonSize } from '../_ui/Button.component';
import { GridColumnType } from '../_ui/grid/enums/Grid-Column-Type.enum';
import {
  MODAL_TYPES,
  useGlobalModalContext,
} from '../_ui/modal/GlobalModal.component';
import { SessionDetail } from './SessionDetail.component';

// Constatns

// API
import {
  useFetchRecentTransactionsQuery,
  useLazyFetchTransactionReportCSVQuery,
} from '../../services/history.api';
import {
  SessionOptions,
  useLazyFetchSessionsQuery,
} from '../../services/session.api';

// Assets
import {
  BoltCharging,
  ChargerEV,
  CheckMark,
  ErrorWarningtraiangle,
  TableNoData,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { NUMBER } from '../../constant/Number.constant';
import { useLazyFetchChargersQuery } from '../../services/charger.api';
import { SCOPE_TYPE } from '../../services/utils';
import { Charger } from '../../stores/types';
import { displayEneryKwh } from '../../utils/Number.Util';
import { getConnectorIcon } from '../Charger/utils';
import { DATE_RANGE } from '../_ui/date-picker/Custom-Date-Picker.component';

interface SessionsProps {
  enableFilterCharger?: boolean;
  enableExportCSV?: boolean;
  dataMap?: Array<string>;
  vehicleId?: string;
  locationId?: string;
  chargerId?: string;
  showTitle?: boolean;
  cardPadding?: string;
  companyId: string;
}

export const Sessions = ({
  locationId,
  enableFilterCharger = true,
  enableExportCSV = true,
  dataMap,
  vehicleId,
  chargerId,
  showTitle = true,
  cardPadding,
  companyId,
}: SessionsProps) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [triggerFetchChargers, { data }] = useLazyFetchChargersQuery();
  const { LAST_WEEK, LAST_MONTH, CUSTOM_RANGE } = DATE_RANGE;

  const chargersGroupByLocation = _.groupBy(data?.entities, 'location.name');
  const ChargersWithLocation = Object.entries(chargersGroupByLocation)
    .map((item) => ({
      ...item,
      chargers: item[1],
    }))
    .filter((item) => item.chargers.length > NUMBER.ZERO);

  const defaultChargers = useMemo(() => {
    return data?.entities.map((charger) => ({
      id: charger.id,
      label: charger.name || '',
    }));
  }, [data]);

  const lastMonth = getLastMonth();
  const [sessionQuery, setSessionQuery] = useQueryParams({
    start: StringParam,
    end: StringParam,
    option: StringParam,
    chargerId: DelimitedArrayParam,
  });

  // Refetch & get date range from url (if present) else default value (i.e. last month) when url query changes
  const dateRange = useMemo(() => {
    return [
      sessionQuery.start
        ? convertMilliSecondToDate(sessionQuery.start)
        : lastMonth[0],
      sessionQuery.end
        ? convertMilliSecondToDate(sessionQuery.end)
        : lastMonth[1],
    ];
  }, [sessionQuery]);

  // if location change && enable dropdown, we need to refetch
  useEffect(() => {
    if (enableFilterCharger) {
      triggerFetchChargers({
        scope: SCOPE_TYPE.COMPANY,
        'filter_eq[locationId]': locationId,
        companyId: companyId,
      });
    }
  }, [companyId, enableFilterCharger, locationId, triggerFetchChargers]);

  // Chargers get updated when either defaultchargers changes or charger id in search param gets changed
  const chargers = useMemo(() => {
    return (
      defaultChargers?.map((charger) => ({
        ...charger,
        selected: sessionQuery.chargerId?.includes(charger.id),
      })) || []
    );
  }, [defaultChargers, sessionQuery.chargerId]);

  const chargersFilterDropdownList = useMemo(() => {
    return ChargersWithLocation.map((key: any) => {
      const children = key.chargers.map((charger: Charger) => {
        return {
          id: charger.id,
          label: charger.name,
          selected: sessionQuery.chargerId?.includes(charger.id as string),
        };
      });

      return {
        id: key[0],
        label: key[0],
        selected: children?.every((child: any) => child.selected) || false,
        children: children || [],
      };
    });
  }, [sessionQuery, ChargersWithLocation]);

  const isAnyChargerSelected = useMemo(() => {
    return chargers.some((charger) => charger.selected);
  }, [chargers]);

  // combine all data to SessionOptions for API
  // if no chargers select, we only send location id.
  const filter = useMemo(() => {
    return {
      sort: 'desc',
      dateRange,
      filter_hasCompletedTx: false,
      vehicleId,
      locationId,
      chargers: enableFilterCharger
        ? chargers
        : vehicleId
        ? []
        : [{ id: chargerId, selected: true }],
      scope: SCOPE_TYPE.COMPANY,
      companyId,
    } as SessionOptions;
  }, [
    dateRange,
    vehicleId,
    locationId,
    enableFilterCharger,
    chargers,
    chargerId,
    companyId,
  ]);

  const [currentPage, setCurrentPage] = useState(1);

  const [isLocalTimezone, setIsLocalTimezone] = useState(true);

  const [
    triggerFetchSessions,
    { data: sessionData, isLoading: isSessionLoading },
  ] = useLazyFetchSessionsQuery();
  useEffect(() => {
    if (filter.companyId) {
      triggerFetchSessions(filter);
    }
  }, [filter]);

  const { transactions, isTransactionLoading } =
    useFetchRecentTransactionsQuery(filter, {
      selectFromResult: (endPoint) => ({
        transactions: endPoint.data ? endPoint.data.entities : [],
        isTransactionLoading: endPoint.isFetching,
      }),
    });

  const [triggerFetchReportCSV] = useLazyFetchTransactionReportCSVQuery();

  const checkIsSessionLoading = useMemo(() => {
    return isSessionLoading || isTransactionLoading;
  }, [isSessionLoading, isTransactionLoading]);

  useEffect(() => {
    // if any filter changes, back to page 1
    setCurrentPage(1);
  }, [filter]);

  const handleClearAllClick = () => {
    setSessionQuery({ chargerId: undefined });
  };

  const renderClearAllButton = () => {
    if (isAnyChargerSelected) {
      return (
        <button
          type='button'
          className='pl-2 text-left'
          onClick={handleClearAllClick}
        >
          <Label
            type={LabelType.DROPDOWN_ITEM_SELECTED}
            color={ColorType.DENIM}
            text={t('session_clear_all')}
          />
        </button>
      );
    }

    return null;
  };

  const handlePillClick = (chargerToBeRemoved: any) => {
    const filteredCharger = chargers.filter((charger) => {
      return charger.id !== chargerToBeRemoved.id && charger.selected === true;
    });
    setSessionQuery({
      chargerId:
        filteredCharger?.length > 0
          ? filteredCharger.map((charger) => charger.id)
          : undefined,
    });
  };

  const selectedFilter = useMemo(() => {
    return sessionQuery.chargerId && sessionQuery.chargerId.length > 0
      ? sessionQuery.chargerId.map((item) => {
          return { type: 'CHARGER', id: item };
        })
      : [];
  }, [sessionQuery]);

  const handleFilterItemClick = (item: any) => {
    if (item.type === 'CHARGER') {
      const filterCharger = [...selectedFilter].filter((i) => i.id !== item.id);
      setSessionQuery({
        chargerId:
          filterCharger?.length > 0
            ? filterCharger.map((charger) => charger.id)
            : undefined,
      });
    } else {
      const filteredChargersList: any =
        chargersFilterDropdownList.find((ele: any) => ele.id === item.id)
          ?.children || [];
      const filteredChargersListIds: string[] = filteredChargersList.map(
        (ele: any) => ele.id,
      );
      const filterCharge = [...selectedFilter].filter((i) => {
        return !filteredChargersListIds.includes(i.id || '');
      });
      setSessionQuery({
        chargerId:
          filterCharge?.length > 0
            ? filterCharge.map((charger) => charger.id)
            : undefined,
      });
    }
  };

  const renderSelectedCharger = () => {
    if (selectedFilter.length === NUMBER.ZERO) {
      return <div />;
    }
    const chargerPillsToShow: any[] = [];
    chargersFilterDropdownList.forEach((ele) => {
      if (ele.selected) {
        chargerPillsToShow.push({
          id: ele.id,
          label: ele.label,
          type: 'LOCATION',
        });
      } else {
        ele.children.forEach((child: any) => {
          if (child.selected) {
            chargerPillsToShow.push({
              id: child.id,
              label: child.label,
              type: 'CHARGER',
            });
          }
        });
      }
    });
    return (
      <>
        {chargerPillsToShow.map((item) => {
          return (
            <Pill
              key={item.id}
              label={item.label}
              hasCloseButton
              onClick={() => handleFilterItemClick(item)}
              translationOn
            />
          );
        })}
      </>
    );
  };

  const handleChargersFilterChange = (items: any) => {
    const existingSelectedFilter = [...selectedFilter];
    const selectedChargerFilters: any[] = [];

    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        const filterObj = { type: 'CHARGER', id: child.id };
        if (child.selected) {
          if (!_.some(selectedFilter, filterObj)) {
            selectedChargerFilters.push(filterObj);
          }
        } else if (_.some(selectedFilter, filterObj)) {
          _.remove(existingSelectedFilter, { id: child.id });
        }
      });
    });

    setSessionQuery({
      chargerId: [...existingSelectedFilter, ...selectedChargerFilters].map(
        (ele) => ele.id,
      ),
    });
  };

  const chargerSelected = (items: any) => {
    const selectedIds = items
      .filter((item: any) => item.selected === true)
      .map((item: any) => {
        return item.id;
      });
    setSessionQuery({ chargerId: selectedIds });
  };

  const getChargerStatus = (row: any) => {
    const rawOcpptatus = row?.rawChargerStatus?.rawChargerStatus;
    const status = ['SUSPENDED_EV', 'SUSPENDED_EVSE'].includes(rawOcpptatus)
      ? rawOcpptatus
      : row?.status;
    return status;
  };

  const handleToggleChange = (selected: boolean) => {
    setIsLocalTimezone(selected);
  };
  const renderSessionDetail = (rowData: any, islocal: boolean) => {
    const SessionDetailInfo = {
      startTime: rowData.startTime || rowData.createTime,
      endTime: rowData.stopTime || rowData.completeTime,
      timeZone: islocal
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : rowData.port?.charger?.location?.timeZone,
      authenticationType: rowData?.startedVia,
      vehicleName: rowData.vehicleName,
      isVehicleActive: rowData.vehicleIdActive,
      rfids: rowData.rfids,
      chargerId: rowData.port?.charger?.id,
      charger: rowData.port?.charger?.name,
      connector: rowData.port?.connectorType,
      connectorSide: t(rowData.port?.physicalLocation.toLowerCase()),
      connectorUrl: 'N/A',
      location: rowData.port?.charger?.location?.name,
      address: generateFullAddress(rowData.port?.charger?.location),
      kwhUsed: rowData.consumedEnergyKwh,
      cost: rowData.billedTotalAmount,
      currency: rowData.billedCurrency,
      sessionStatus: rowData.status,
      userEmail: rowData.userEmail,
      statusHistory: generateChargerStatusHistory(
        getChargerStatus(rowData),
        rowData.startTime || rowData.createTime,
        rowData.stopTime || rowData.completeTime,
        t,
        islocal
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : rowData.port?.charger?.location?.timeZone,
      ),
      receiptId: rowData.receiptId,
      paymentTerminalSerialNumber: rowData.paymentTerminalSerialNumber,
      paymentTerminalSessionId: rowData.paymentTerminalSessionId,
    };
    return <SessionDetail sessionData={SessionDetailInfo} />;
  };

  const rowClick = useCallback(
    (rowData: any) => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('session_detail'),
        height: 'max-content',
        onRenderBody: () => renderSessionDetail(rowData, isLocalTimezone),
      });
    },
    [isLocalTimezone],
  );

  const handleButtonClick = () => {
    const { sort, ...rest } = filter;
    triggerFetchReportCSV(rest);
  };

  const renderSelectedChargers = () => {
    if (isAnyChargerSelected) {
      return (
        <div className='gap-2 mt-5 inline-flex flex-wrap'>
          {renderSelectedCharger()}
          {renderClearAllButton()}
        </div>
      );
    }
    return null;
  };

  const columnsSettings = [
    {
      key: 'startedVia',
      title: t('session_auth_type'),
      component: (row: any) => {
        return (
          <Label
            text={t(row.startedVia)}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={checkIsSessionLoading}
            skeletonHeight='h-5'
            skeletonWidth='w-full'
          />
        );
      },
    },
    {
      key: 'port.charger.location.name',
      title: t('location'),
      component: (row: any) => {
        return (
          <Label
            text={t(row?.port?.charger?.location?.name)}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={checkIsSessionLoading}
            skeletonHeight='h-5'
            skeletonWidth='w-full'
          />
        );
      },
    },
    {
      key: 'port.charger.name',
      title: t('charger'),
      class: 'w-[192px]',
      component: (row: any) => (
        <div className='flex flex-row'>
          <Pill
            label={row?.port?.charger?.name}
            bgColor={ColorType.GREY2}
            iconLeft={ChargerEV}
            isLoading={checkIsSessionLoading}
          />
        </div>
      ),
    },
    {
      key: 'createTime|startTime',
      title: t('session_start_time'),
      type: GridColumnType.TIMEZONE,
      component: (row: any) => {
        return (
          <Label
            text={
              checkIsSessionLoading
                ? ''
                : formatInTimeZone(
                    row?.createTime || row?.startTime || '',
                    isLocalTimezone
                      ? Intl.DateTimeFormat().resolvedOptions().timeZone
                      : row.port.charger.location.timeZone,
                    'LLL dd, h:mm a',
                    getLocale(),
                  )
            }
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={checkIsSessionLoading}
            skeletonHeight='h-5'
            skeletonWidth='w-full'
          />
        );
      },
    },
    {
      key: 'port.connectorType|port.physicalLocation',
      title: t('port'),
      component: (row: any) => {
        if (checkIsSessionLoading) {
          return <Skeleton height='h-5' width='w-full' />;
        }
        return (
          <div className='flex items-center gap-2'>
            <Icon
              key={row?.port?.connectorType}
              size={IconSize.SIZE_20x20}
              src={getConnectorIcon(row?.port?.connectorType)}
              color={ColorType.BLACK}
            />
            <Label
              style={{ width: '80px' }}
              text={t(`${row?.port?.physicalLocation.toLowerCase()}`)}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
        );
      },
    },
    {
      key: 'status',
      title: t('status'),
      class: 'w-[180px]',
      component: (row: any) => {
        let statusIcon;
        let color;
        const status = getChargerStatus(row);
        if (
          [
            'preparing',
            'in_progress',
            'finishing',
            'suspended_ev',
            'suspended_evse',
          ].includes(status?.toLowerCase())
        ) {
          statusIcon = BoltCharging;
          color = ColorType.ACCENT1;
        } else if (row?.status?.toLowerCase() === 'failed') {
          statusIcon = ErrorWarningtraiangle;
          color = ColorType.NEGATIVE1;
        } else {
          statusIcon = CheckMark;
          color = ColorType.POSITIVE1;
        }
        if (checkIsSessionLoading) {
          return <Skeleton height='h-5' width='w-full' />;
        }
        return (
          <div className='flex flex-row gap-2'>
            {!checkIsSessionLoading && (
              <Icon src={statusIcon} color={color} size={IconSize.SIZE_20x20} />
            )}
            <Label
              text={(status || t('completed'))
                .replace('FAILED', t('failed'))
                .replace('PREPARING', t('preparing'))
                .replace('IN_PROGRESS', t('in_use'))
                .replace('SUSPENDED_EVSE', t('suspended_evse'))
                .replace('SUSPENDED_EV', t('suspended_ev'))
                .replace('FINISHING', t('finishing'))
                .replace('ENDED', t('completed'))}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={checkIsSessionLoading}
              skeletonHeight='h-5'
              skeletonWidth='w-full'
            />
          </div>
        );
      },
    },
    {
      key: 'consumedEnergyKwh',
      title: t('overview_energy_used'),
      class: 'w-[120px]',
      component: (row: any) => (
        <Label
          text={
            row.consumedEnergyKwh || row.consumedEnergyKwh === 0
              ? displayEneryKwh(row.consumedEnergyKwh, t('kwh'))
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={checkIsSessionLoading}
          skeletonHeight='h-5'
          skeletonWidth='w-full'
        />
      ),
    },
    {
      key: 'billedTotalAmount',
      title: t('session_cost'),
      class: 'w-[120px]',
      component: (row: any) => (
        <Label
          text={
            row.billedTotalAmount || row.billedTotalAmount === 0
              ? `${convertToLocaleCurrency(
                  row.billedTotalAmount,
                  row.billedCurrency,
                )}`
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={checkIsSessionLoading}
          skeletonHeight='h-5'
          skeletonWidth='w-full'
        />
      ),
    },
  ];

  const recentSessions = useMemo(() => {
    return getSortedRecentSessions(
      sessionData?.entities || [],
      transactions,
      chargers,
      dateRange,
    );
  }, [sessionData?.entities, transactions, chargers, dateRange]);

  const renderRecentSessionGrid = () => {
    if (
      checkIsSessionLoading ||
      (recentSessions && recentSessions.length > 0)
    ) {
      return (
        <div className='mt-6'>
          <Grid
            onRowClick={rowClick}
            pageIndex={currentPage}
            loadPage={(page) => setCurrentPage(page)}
            local
            columns={columnsSettings.filter(
              (setting) => !dataMap || dataMap?.includes(setting.key),
            )}
            data={recentSessions}
            totalPage={Math.ceil(recentSessions.length / 20)}
            primaryKey='id'
            isLoading={checkIsSessionLoading}
            skeletonRowCount={10}
            scrollTableClass='overflow-hidden'
            setIsLocalTimezone={handleToggleChange}
            isLocalTimezone={isLocalTimezone}
            switchClass='w-5 h-2.5'
            switchBtnClass='w-2 h-2 top-px left-px'
            translateClass='translate-x-2.5'
          />
        </div>
      );
    }
    return (
      <div className='flex flex-col gap-1 items-center justify-center py-4 h-52'>
        <Icon className='grey5' src={TableNoData} size={IconSize.SIZE_48x48} />
        <Label
          text={t('recent_session_no_data')}
          type={LabelType.H4}
          color={ColorType.GREY5}
        />

        <Label
          text={t('recent_session_no_data_desc')}
          type={LabelType.BODY3}
          color={ColorType.GREY5}
          className='text-base'
        />
      </div>
    );
  };

  return (
    <Card
      className={cardPadding}
      title={showTitle ? t('session_recent_sessions') : ''}
    >
      <div className='flex mt-3 w-full'>
        <div className='flex w-4/5'>
          {enableFilterCharger && (
            <div className='mr-2'>
              <Dropdown
                placeholder={t('charger')}
                placeholderLabelType={LabelType.SESSION_DROPDOWN_HEADER}
                headerWidth='auto'
                type={DropdownType.CHECKBOX_TREE}
                items={chargersFilterDropdownList}
                onItemClick={handleChargersFilterChange}
                showFooter
                dataTestId='session-select-charger'
              />
            </div>
          )}
          <CustomDatePicker
            optionList={[LAST_WEEK, LAST_MONTH, CUSTOM_RANGE]}
            format='MMM d,yyyy'
            onChange={(option, range) => {
              setSessionQuery({
                option,
                start: convertDateToString(range[0]),
                end: convertDateToString(range[1]),
              });
            }}
            selectedOption={sessionQuery.option || DATE_RANGE.LAST_MONTH}
            selectedDate={
              sessionQuery.start && sessionQuery.end
                ? [
                    convertMilliSecondToDate(sessionQuery.start),
                    convertMilliSecondToDate(sessionQuery.end),
                  ]
                : []
            }
            showHighligtHeader={false}
            dataTestId='session-select-date-range'
          />
        </div>
        {enableExportCSV && (
          <div className='flex justify-end w-1/5'>
            <Button
              size={ButtonSize.SMALL}
              label={t('export_csv')}
              type={ButtonType.TERTIARY}
              onClick={handleButtonClick}
              dataTestId='session-select-export-csv'
            />
          </div>
        )}
      </div>

      {enableFilterCharger && renderSelectedChargers()}
      {renderRecentSessionGrid()}
    </Card>
  );
};
