import {
  CCS1,
  CCS2,
  CHAdeMO,
  J1772,
  Type2Mennekes,
  tesla,
} from '../../assets/icons';
import { NUMBER } from '../../constant/Number.constant';
import {
  CHARGER_STATUS,
  CONNECTOR_TYPES,
  ILLEGAL_KEYWORDS,
  OCPP_STATUS,
} from '../../constant/Text.constant';
import { Charger } from '../../stores/types';
import { convertToDate, getDifferenceInMinutes } from '../../utils/Date.Util';

// Based on max length mentioned in chargelab.atlassian.net/wiki/spaces/PE/pages/1606942730/Data+Format#OCPP-Id
export const OCPP_ID_LENGTH = 48;
export const SERIAL_NUMBER_LENGTH = 48;
export const CHARGER_VISIBLE_ON_MAP = 'Visible';
export const CHARGER_NOT_VISIBLE_ON_MAP = 'Hidden';

export const getConnectorIcon = (type: string) => {
  switch (type) {
    case CONNECTOR_TYPES.J1772:
      return J1772;
    case CONNECTOR_TYPES.CCS:
      return CCS1;
    case CONNECTOR_TYPES.CHADEMO:
      return CHAdeMO;
    case CONNECTOR_TYPES.TYPE_2:
      return Type2Mennekes;
    case CONNECTOR_TYPES.TYPE_2_CCS:
      return CCS2;
    case CONNECTOR_TYPES.TESLA:
      return tesla;
    default:
      return '';
  }
};

const sortChargrByStatus = (
  charger: Charger,
  statusFilter: any[],
  isAnyStatusSelected: boolean,
) => {
  return (
    !isAnyStatusSelected ||
    statusFilter.some(
      (item) =>
        (item.status === charger.status &&
          charger.status.toLowerCase() !== OCPP_STATUS.CHARGING) ||
        charger.ports.some((port) =>
          port.status?.toLowerCase() === OCPP_STATUS.CHARGING
            ? port.rawChargerStatus.rawChargerStatus?.toLowerCase() ===
              item.status
            : port.status?.toLowerCase() === item.status,
        ),
    )
  );
};

export const getCountriesByPreference = (countries: any) => {
  const result: any = [];
  let countryCanada = {};
  let countryAmerica = {};

  countries.forEach((country: any) => {
    const isCanada = country.isoCode === 'CA';
    const isUS = country.isoCode === 'US';
    if (isUS) {
      countryAmerica = country;
    }
    if (isCanada) {
      countryCanada = country;
    }
    if (!isCanada && !isUS) {
      result.push(country);
    }
  });
  result.unshift(countryAmerica);
  result.unshift(countryCanada);
  return result;
};

export const getHighlightedTextColor = (inputString: string) => {
  const ignoreCssFontSizeClassNames = [
    'text-xs',
    'text-sm',
    'text-lg',
    'text-base',
    'text-xl',
  ];
  const colorClassPattern = /text-(\w+)/g;
  const matchedColors = inputString.match(colorClassPattern);
  if (matchedColors?.length) {
    const filterOutFontSizeClass = matchedColors.filter(
      (item) => ignoreCssFontSizeClassNames.indexOf(item) === -1,
    );
    return filterOutFontSizeClass.length
      ? filterOutFontSizeClass[0].split('-')[1]
      : '';
  }
  return '';
};

export const copyChargingToInuse = (status: string) =>
  status.toLowerCase() === OCPP_STATUS.CHARGING ? 'in_use' : status;

export const getValidRawStatus = (status: string) => {
  const inValidStatus = ['preparing', 'finishing'];
  if (inValidStatus.includes(status?.toLowerCase())) {
    return CHARGER_STATUS.AVAILABLE;
  }
  return status;
};

export const getRowStatus = (row: Charger) => {
  if (row.ports?.length === 1) {
    return row.status === OCPP_STATUS.CHARGING
      ? {
          status: getValidRawStatus(
            row.ports[0]?.rawChargerStatus?.rawChargerStatus?.toLowerCase(),
          ),
          timeStamp: getDifferenceInMinutes(
            new Date(),
            convertToDate(
              row.ports[0]?.rawChargerStatus?.rawChargerStatusChangedTime,
            ),
          ),
        }
      : {
          status: row.status,
          timeStamp: '',
        };
  }
  return {
    ports: row.ports,
    status: row.status,
  };
};

export const handleValidateId = (value: any) => {
  const regex = /^([a-zA-Z0-9*-_|@])+$/;
  return (
    regex.test(value) && ILLEGAL_KEYWORDS.indexOf(value) === NUMBER.MINUS_ONE
  );
};

export const extractSections = (text: string) => {
  const sections: Record<string, string> = {};

  // Define an array with section names and corresponding regex patterns, typed explicitly
  const patterns: { section: string; regex: RegExp }[] = [
    {
      section: 'diagnosticSummary',
      regex: /(?<=\*\*Diagnostic Summary\*\*:\s*)([\s\S]*?)(?=\n\n\*\*|$)/,
    },
    {
      section: 'issueAnalysis',
      regex: /(?<=\*\*Issue Analysis\*\*:\s*)([\s\S]*?)(?=\n\n\*\*|$)/,
    },
    {
      section: 'actionableInsights',
      regex: /(?<=\*\*Actionable Insights\*\*:\s*)([\s\S]*?)(?=\n\n\*\*|$)/,
    },
    { section: 'reminders', regex: /(?<=Reminder:\s*)([\s\S]*?)(?=\n\n|$)/ },
  ];

  // Use forEach to iterate over patterns and extract matches
  patterns.forEach(({ section, regex }) => {
    const match = text.match(regex);
    sections[section] = match ? match[0].trim().replace('- ', '') : '';
  });

  return sections;
};

export const formatSparkResult = (data: string) => {
  const { diagnosticSummary, issueAnalysis, actionableInsights, reminders } =
    extractSections(data);

  const actionableInsightsArray = actionableInsights
    ? actionableInsights
        .split('- ')
        .map((item) => item.trim())
        .filter((item) => item.length > 0)
    : [];

  const remindersArray = reminders
    ? reminders
        .split('- ')
        .map((item) => item.trim())
        .filter((item) => item.length > 0)
    : [];

  return {
    diagnostic: diagnosticSummary,
    issues: issueAnalysis,
    insights: actionableInsightsArray,
    reminders: remindersArray,
  };
};
