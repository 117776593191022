interface SkeletonProps {
  height?: string;
  width?: string;
  count?: number;
  className?: string;
  innerClassName?: string;
  loadingColor?: string;
  isPill?: boolean;
  style?: any;
}

export const Skeleton = ({
  height,
  width = 'w-full',
  count = 1,
  className = '',
  innerClassName = '',
  loadingColor = 'bg-grey2',
  isPill = false,
  style = {},
}: SkeletonProps) => {
  return (
    <div
      className={`flex items-center justify-left animate-pulse ${className}`}
    >
      {Array(count)
        .fill(0)
        .map((item, index) => (
          <div
            className={`${loadingColor} ${width} ${height} ${
              isPill ? innerClassName : 'rounded'
            } `}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{ ...style }}
          />
        ))}
    </div>
  );
};
