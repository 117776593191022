import { forEach } from 'lodash';
import { Charger as ChargerObj, Location } from '../../stores/types';
import { InternalErrorCode } from '../../stores/types/error.interface';
import {
  Breaker,
  Charger,
  Circuit,
  UnmanagedLoad,
} from '../../stores/types/pm.interface';

export const ELECTRIC_SUPPLY = {
  SPLIT_PHASE: 'SPLIT_PHASE',
  THREE_PHASE: 'THREE_PHASE',
};

export const SPLIT_PHASE_WIRING = {
  L_L: 'L_L',
  L_N: 'L_N',
};

export const CHARGER_TYPE = {
  LEVEL_1: 'LEVEL_1',
  LEVEL_2: 'LEVEL_2',
  DC_FAST: 'DC_FAST',
};

/**
 * Function transforms BE wiring value to what FE needs to display
 */
export const transformWiring = (value: string) => {
  switch (value) {
    case 'L1_N':
      return 'A, N';
    case 'L2_N':
      return 'B, N';
    case 'L3_N':
      return 'C, N';
    case 'L1_L2':
      return 'A, B';
    case 'L2_L3':
      return 'B, C';
    case 'L3_L1':
      return 'C, A';
    case 'L1_L2_L3':
      return 'A, B, C';
    case 'L_N':
      return '120V';
    default:
      return '';
  }
};

/**
 * Function transforms wiring value during Split Phase of circuit what Design needs to display
 */
export const transformWiringForSplitPhase = (value: string) => {
  switch (value) {
    case 'L_L':
      return 'Split-phase 240V';
    case 'L_N':
      return 'Split-phase 120V';
    default:
      return '';
  }
};

/**
 * Function transforms BE electric supply value to what FE needs to display
 */
export const transformElectricSupply = (value: string) => {
  switch (value) {
    case ELECTRIC_SUPPLY.SPLIT_PHASE:
      return 'Split-phase';
    case ELECTRIC_SUPPLY.THREE_PHASE:
      return '3-phase';
    default:
      return '';
  }
};

/**
 * Function takes wiring value returned by BE
 * Transforms it in FE wiring value
 * Prepends FE value with 'Phase' text (as per specific UI need)
 */
export const prependWiringText = (value: string) => {
  const prependWiringArray = transformWiring(value)
    .split(',')
    .map((item) => {
      return `Phase ${item}`;
    });
  return prependWiringArray.join(', ');
};

/**
 * Function checks if L_L wiring is present in circuit with Split Phase electric supply
 */
export const checkIfLLWiring = (
  isSplitPhase: boolean,
  chargerWiring: string,
) => {
  return isSplitPhase && SPLIT_PHASE_WIRING.L_L === chargerWiring;
};

export const checkIfBreakerWarning = (data: any) => {
  let error = false;
  const breakerData = Array.isArray(data) ? data : [];

  forEach(breakerData, (item) => {
    const breakerItem = { ...item };
    if (breakerItem.errors?.length > 0 && !error) {
      const exceptionError = breakerItem.errors.find(
        (errorItem: any) => errorItem.code === InternalErrorCode.ERR_PM_1001,
      );
      error = !!exceptionError;
    } else if (breakerItem.subBreakers.length > 0 && !error) {
      error = checkIfBreakerWarning(breakerItem.subBreakers);
    }
  });

  return error;
};

export const checkIfLocationWarning = (data: any) => {
  let isError = false;
  const [, locationData] = data;

  forEach(locationData, (location) => {
    if (location?.mainBreakers?.length > 0 && !isError) {
      const mainBreakerData = location?.mainBreakers;
      const mainBreaker = mainBreakerData[0];

      if (mainBreaker.errors?.length > 0) {
        const exceptionError = mainBreaker.errors.find(
          (error: any) => error.code === 'LoadBalancerException',
        );
        isError = !!exceptionError;
      } else if (mainBreaker.subBreakers?.length > 0) {
        isError = checkIfBreakerWarning(mainBreaker.subBreakers);
      }
    }
  });

  return isError;
};
const sortArrayByObjectKey = (array: any, key: string) => {
  const newArray = [...array];
  return (newArray || []).sort((prev: any, next: any) => {
    const prevKey = prev?.[key]?.toLowerCase() || '';
    const nextKey = next?.[key]?.toLowerCase() || '';
    if (prevKey < nextKey) {
      return -1;
    }
    return 1;
  });
};

const getChargerFromLocation = (deviceId: string, chargers: any) => {
  const foundCharger = chargers?.find((c: any) => deviceId === c.id);
  return foundCharger;
};

export const sortBreakerChargers = (chargers: Charger[], locations: any) => {
  const chargersList = chargers.map((charger) => {
    const chargerData = getChargerFromLocation(
      charger.deviceId,
      locations?.chargers || [],
    );
    return {
      ...charger,
      displayName: chargerData?.displayName,
      chargerStatus: chargerData?.status,
      isPowerManaged: !!chargerData,
      firmwareVersion: chargerData?.firmwareVersion,
      pmIncompatibleReason: chargerData?.pmIncompatibleReason,
    };
  });
  return sortArrayByObjectKey(chargersList, 'displayName');
};

export const sortUnmanagedLoads = (loads: UnmanagedLoad[]) => {
  return sortArrayByObjectKey(loads, 'name');
};

export const sortLocations = (locations: Location[]) => {
  return sortArrayByObjectKey(locations, 'name');
};

export const sortBreakers = (breakers: Circuit[]) => {
  return sortArrayByObjectKey(breakers, 'name');
};

export const sortSubBreakers = (subBreakers: Breaker[]) => {
  return sortArrayByObjectKey(subBreakers, 'name');
};

export const getBreakersChargers = (data: any) => {
  let chargers: string[] = [];
  const breakerData = Array.isArray(data) ? data : [];
  forEach(breakerData, (item) => {
    if (item.chargers?.length) {
      const chargerIds = item.chargers.map(
        (charger: Charger) => charger.deviceId,
      );
      chargers = [...chargers, ...chargerIds];
    }
    if (item.subBreakers.length) {
      chargers = [...chargers, ...getBreakersChargers(item.subBreakers)];
    }
  });
  return chargers;
};

export const getAllCircuitChargerIds = (data: Circuit[]) => {
  let chargerIds: string[] = [];

  data.forEach((item) => {
    if (item?.mainBreakers) {
      const mainBreakerData = item?.mainBreakers?.[0];
      if (mainBreakerData.chargers?.length) {
        const ids = mainBreakerData.chargers.map(
          (charger: Charger) => charger.deviceId,
        );
        chargerIds = [...chargerIds, ...ids];
      }
      if (mainBreakerData.subBreakers?.length) {
        chargerIds = [
          ...chargerIds,
          ...getBreakersChargers(mainBreakerData.subBreakers),
        ];
      }
    }
  });
  return chargerIds;
};

export const sortNotPowerManagedChargers = (chargers: ChargerObj[]) => {
  return sortArrayByObjectKey(chargers, 'displayName');
};

/**
 * Function transforms chargerType from BE to what Ui specific values
 * Note: Following constants are dependent on BE
 * Hence keeping default case for returining charger type as it is, in order to avoid displaying empty charger type on UI
 */
export const transformChargerType = (chargerType: string) => {
  switch (chargerType) {
    case CHARGER_TYPE.LEVEL_1:
      return 'Level 1';
    case CHARGER_TYPE.LEVEL_2:
      return 'Level 2';
    case CHARGER_TYPE.DC_FAST:
      return 'DC Fast';
    default:
      return chargerType;
  }
};

export const arrayRange = (start: number, stop: number) =>
  Array.from(
    { length: (stop - start) / 1 + 1 },
    (value, index) => start + index * 1,
  );

export const defaultLoadConfig = {
  editId: null,
  editChargerOption: null,
  loadType: null,
  deviceId: null,
  chargerDetails: null,
  ctdb: true, // connected to dedicated breaker
  dedicatedBreaker: {
    breakerRating: 0,
    breakerSpace: '',
  },
  name: null,
  fixedAmperage: 0,
  wiringPhase: '',
  model: '',
  type: '',
  minAmp: 0,
  maxAmperage: 0,
  maxAmpsOverride: 0,
  maxAmpsOverrideFactoryDefault: false,
  zeroAmpSupport: false,
  overrideZeroAmperage: false,
  supportZeroAmpsOverrideFactoryDefault: false,
};

export const BREAKER_LOAD_TYPES = {
  CHARGER: 'CHARGER',
  UNMANAGEDlOAD: 'UNMANAGEDlOAD',
};

export const VALID_WIRING_PHASES = [
  'L_L',
  'L_N',
  'L1_N',
  'L2_N',
  'L3_N',
  'L1_L2',
  'L2_L3',
  'L3_L1',
  'L1_L3',
  'L1_L2_L3',
];

export const unmanagedLoadNames = (
  parentBreakers: Breaker[],
  existingNames: any,
) => {
  if (!Array.isArray(parentBreakers)) {
    return existingNames;
  }
  parentBreakers.forEach((breakerData: Breaker) => {
    breakerData.unmanagedLoads.forEach((item: any) => {
      if (item.name) {
        existingNames.push({ id: item.id, name: item.name });
      }
    });
    if (breakerData.subBreakers.length > 0) {
      unmanagedLoadNames(breakerData.subBreakers, existingNames);
    }
  });
  return existingNames;
};

export const isDuplicateLoadName = (
  loadData: any,
  activeIndex: number,
  isEdit: boolean,
  mainBreakers: any,
) => {
  const existingSavedNames = unmanagedLoadNames(mainBreakers ?? [], []);
  // find from current names
  const findIndex1 = loadData.findIndex(
    (item: any, index: number) =>
      item.name?.toLowerCase().trim() ===
        loadData[activeIndex].name?.toLowerCase().trim() &&
      index !== activeIndex,
  );
  // find from saved names
  let findIndex2 = existingSavedNames.findIndex(
    (item: any) =>
      item.name.toLowerCase().trim() ===
      loadData[activeIndex].name?.toLowerCase().trim(),
  );

  // handle edit mode
  if (isEdit) {
    findIndex2 = existingSavedNames.findIndex(
      (item: any) =>
        item.name.toLowerCase().trim() ===
          loadData[activeIndex].name?.toLowerCase().trim() &&
        item.id !== loadData[activeIndex].editId,
    );
  }

  return findIndex2 !== -1 || findIndex1 !== -1;
};

export const validateBreakerLoad = (
  loadData: any,
  activeIndex: number,
  mainBreakers: Breaker[],
  isEdit: boolean,
) => {
  // submit validation for charger load
  if (!loadData[activeIndex].loadType) {
    return true;
  }

  if (loadData[activeIndex].loadType === BREAKER_LOAD_TYPES.CHARGER) {
    // validate charger is selected or not
    if (!loadData[activeIndex].deviceId) {
      return true;
    }

    // validate maxAmperage override value
    if (loadData[activeIndex].maxAmpsOverrideFactoryDefault) {
      if (!loadData[activeIndex]?.maxAmpsOverride) {
        return true;
      }
    }
  } else if (
    !loadData[activeIndex].name ||
    !loadData[activeIndex].fixedAmperage ||
    isDuplicateLoadName(loadData, activeIndex, isEdit, mainBreakers)
  ) {
    return true;
  }

  // validate Load is connected to a dedicated breaker option
  if (loadData[activeIndex].ctdb) {
    if (!loadData[activeIndex]?.dedicatedBreaker?.breakerRating) {
      return true;
    }
  }

  const checkedOptions = loadData[activeIndex].wiringPhase.split('_');
  // validate wiring phase based on circuit electricity load
  if (checkedOptions.length < 1) {
    return true;
  }

  // validate for valid wiring phase selection
  if (VALID_WIRING_PHASES.indexOf(loadData[activeIndex].wiringPhase) === -1) {
    return true;
  }

  return false;
};

const randomNumber = () => {
  return new Date().getTime().toString() + Math.floor(Math.random() * 1000000);
};

export const loadRequestObjects = (
  parentBreaker: any,
  loadData: any,
  type: string,
) => {
  if (type === BREAKER_LOAD_TYPES.UNMANAGEDlOAD) {
    return loadData.map((item: any) => {
      const { name, fixedAmperage, wiringPhase, dedicatedBreaker } = item;
      // Dynamic breaker name for the dedicated breaker
      const copyParentBreakerIdName = { ...parentBreaker };
      copyParentBreakerIdName.name = `Dedicated Breaker-${randomNumber()}`;

      if (item.ctdb) {
        return {
          name,
          fixedAmperage,
          wiringPhase,
          dedicatedBreaker: {
            ...dedicatedBreaker,
            ...copyParentBreakerIdName,
          },
        };
      }
      return { name, fixedAmperage, wiringPhase };
    });
  }

  return loadData.map((item: any) => {
    const { deviceId, wiringPhase, dedicatedBreaker } = item;
    if (item.ctdb) {
      // Dynamic breaker name for the dedicated breaker
      const copyParentBreakerIdName = { ...parentBreaker };
      copyParentBreakerIdName.name = `Dedicated Breaker-${randomNumber()}`;

      return {
        deviceId,
        chargerWiring: wiringPhase,
        dedicatedBreaker: {
          ...dedicatedBreaker,
          ...copyParentBreakerIdName,
        },
      };
    }
    return { deviceId, chargerWiring: wiringPhase };
  });
};

export const findParentOfDedicatedBreaker = (
  parentBreakers: Breaker[],
  id: string,
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of parentBreakers) {
    // If the current object's ID matches the target ID, return it
    if (obj.id === id) {
      return obj;
    }
    // If the current object has children (assuming the children property is named "children"),
    // recursively search through its children
    if (obj.subBreakers && obj.subBreakers.length > 0) {
      const foundInChildren: any = findParentOfDedicatedBreaker(
        obj.subBreakers,
        id,
      );
      // If the object is found in the children, return it
      if (foundInChildren) {
        return foundInChildren;
      }
    }
  }
  // If the object is not found in the array or its children, return null
  return null;
};

export const getUnmanagedBreakerLoad = (
  parentBreakerIdName: any,
  breakerData: any,
  mainBreakers: any,
  editId?: string,
) => {
  const mainBreaker = breakerData.dedicatedBreaker
    ? findParentOfDedicatedBreaker(mainBreakers, breakerData.parentBreakerId)
    : { ...breakerData };

  const unmanagedLoads = mainBreaker.unmanagedLoads.map((item: any) => ({
    id: item.id,
    name: item.name,
    fixedAmperage: item.fixedAmperage,
    wiringPhase: item.wiringPhase,
  }));

  const dedicatedUnmanagedLoad = mainBreaker.subBreakers.reduce(
    (breakerLoads: any, subBreaker: any) => {
      if (subBreaker.dedicatedBreaker && subBreaker.unmanagedLoads.length) {
        const { id, name, wiringPhase, fixedAmperage } =
          subBreaker.unmanagedLoads[0];
        const { breakerRating, breakerSpace } = subBreaker;
        breakerLoads.push({
          id,
          name,
          wiringPhase,
          fixedAmperage,
          dedicatedBreaker: {
            ...parentBreakerIdName,
            name: `Dedicated Breaker-${randomNumber()}`,
            breakerRating,
            breakerSpace,
          },
        });
      }
      return breakerLoads;
    },
    [],
  );

  const filterEditedData = [
    ...unmanagedLoads,
    ...dedicatedUnmanagedLoad,
  ].filter((item: any) => item.id !== editId);

  return filterEditedData.map((item: any) => {
    delete item.id;
    return item;
  });
};

export const getChargersBreakerLoad = (
  parentBreakerIdName: any,
  breakerData: any,
  mainBreakers: any,
  editId?: string,
) => {
  const mainBreaker = breakerData.dedicatedBreaker
    ? findParentOfDedicatedBreaker(mainBreakers, breakerData.parentBreakerId)
    : { ...breakerData };

  const chargerLoads = mainBreaker.chargers.map((item: any) => ({
    deviceId: item.deviceId,
    chargerWiring: item.chargerWiring,
  }));

  const dedicatedChargersLoad = mainBreaker.subBreakers.reduce(
    (breakerLoads: any, subBreaker: any) => {
      if (subBreaker.dedicatedBreaker && subBreaker.chargers.length) {
        const { deviceId, chargerWiring } = subBreaker.chargers[0];
        const { breakerRating, breakerSpace } = subBreaker;
        breakerLoads.push({
          deviceId,
          chargerWiring,
          dedicatedBreaker: {
            ...parentBreakerIdName,
            name: `Dedicated Breaker-${randomNumber()}`,
            breakerRating,
            breakerSpace,
          },
        });
      }
      return breakerLoads;
    },
    [],
  );

  return [...chargerLoads, ...dedicatedChargersLoad].filter(
    (item: any) => item.deviceId !== editId,
  );
};

export const circuitBreakerOptions = (
  parentBreakers: Breaker[],
  dropdownOptions: any,
) => {
  if (!Array.isArray(parentBreakers)) {
    return dropdownOptions;
  }
  parentBreakers.forEach((breakerData: Breaker) => {
    if (!breakerData.dedicatedBreaker) {
      dropdownOptions.push({
        id: breakerData.id,
        label: breakerData.name,
        selected: false,
      });
    }
    if (breakerData.subBreakers.length > 0) {
      circuitBreakerOptions(breakerData.subBreakers, dropdownOptions);
    }
  });

  return dropdownOptions;
};

export const minRequireRating = (breakerData?: Breaker) => {
  let totalAmp = 0;
  if (!breakerData) return totalAmp;

  const breakerMinAmps = [
    ...breakerData.chargers,
    ...breakerData.unmanagedLoads,
  ].reduce((ampTotal: number, loadObj: any) => {
    ampTotal += +loadObj.minAmp || +loadObj.fixedAmperage || 0;
    return ampTotal;
  }, 0);

  totalAmp += breakerMinAmps;
  if (!breakerData.subBreakers.length) {
    return totalAmp;
  }
  breakerData.subBreakers.forEach((breakerObj: Breaker) => {
    totalAmp += minRequireRating(breakerObj);
  });

  return totalAmp;
};
