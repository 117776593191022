import { useTranslation } from 'react-i18next';
import { Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { Charger } from '../../../stores/types';
import { ColorType, FormInput, Icon, Label, LabelType } from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';

type AdditionalInfoProps = {
  charger: Charger;
  editEnabled: boolean;
  isLoading: boolean;
  handleInputChange: any;
};

export const AdditionalInfo = ({
  charger,
  editEnabled,
  isLoading,
  handleInputChange,
}: AdditionalInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={`flex flex-col gap-0 pt-4 ${editEnabled ? 'pb-3' : ''}`}>
      <Label
        text={t('charger_additional_info')}
        type={LabelType.LABEL_M}
        color={ColorType.GREY5}
        style={{ minHeight: '40px' }}
      />
      <div className={`flex flex-col ${editEnabled ? 'gap-1' : 'gap-0'}`}>
        <div className='flex flex-row items-start min-h-[40px]'>
          <div className='flex flex-row gap-1 min-w-[148px] max-w-[148px] mr-2.5 items-start'>
            <Label
              text={t('charger_directions')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
            />
            <Tooltip>
              <TooltipTrigger>
                <Icon src={Info} size={IconSize.SIZE_20x20} />
              </TooltipTrigger>
              <TooltipContent>
                <Label
                  text={t('charger_tip')}
                  type={LabelType.BODY3}
                  color={ColorType.WHITE}
                />
              </TooltipContent>
            </Tooltip>
          </div>
          {!editEnabled && (
            <Label
              text={charger?.directions}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              className='text-ellipsis overflow-hidden text-justify'
              isLoading={isLoading}
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <div className='flex-1'>
              <FormInput
                defaultValue={charger?.directions}
                onChange={(event: any) =>
                  handleInputChange('directions', event.target.value)
                }
                width='100%'
                height='36px'
              />
            </div>
          )}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_parking_space')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <div className='flex-1'>
            {!editEnabled && (
              <Label
                text={charger?.parkingSpot}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                className='text-ellipsis overflow-hidden text-justify'
                isLoading={isLoading}
                skeletonWidth='w-52'
                skeletonHeight='h-5'
              />
            )}
            {editEnabled && (
              <FormInput
                defaultValue={charger?.parkingSpot}
                onChange={(event: any) =>
                  handleInputChange('parkingSpot', event.target.value)
                }
                width='100%'
                height='36px'
              />
            )}
          </div>
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_unit')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <div className='flex-1'>
            {!editEnabled && (
              <Label
                text={charger?.associatedBuildingUnit}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                className={`${
                  !isLoading ? 'w-[190px]' : ''
                } text-ellipsis overflow-hidden text-justify`}
                isLoading={isLoading}
                skeletonWidth='w-52'
                skeletonHeight='h-5'
              />
            )}
            {editEnabled && (
              <div className='flex-1'>
                <FormInput
                  defaultValue={charger?.associatedBuildingUnit}
                  onChange={(event: any) =>
                    handleInputChange(
                      'associatedBuildingUnit',
                      event.target.value,
                    )
                  }
                  width='100%'
                  height='36px'
                />
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <div className='flex flex-row gap-1 min-w-[148px] max-w-[148px] mr-2.5 items-start'>
            <Label
              text={t('charger_internal_note')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
            />
          </div>
          <div className='flex-1'>
            {!editEnabled && (
              <Label
                text={charger?.internalNote}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                className={`${
                  !isLoading ? 'w-[190px]' : ''
                } text-ellipsis overflow-hidden text-justify`}
                isLoading={isLoading}
                skeletonWidth='w-52'
                skeletonHeight='h-5'
              />
            )}
            {editEnabled && (
              <FormInput
                defaultValue={charger?.internalNote}
                onChange={(event: any) =>
                  handleInputChange('internalNote', event.target.value)
                }
                width='100%'
                height='36px'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
