import {
  CHARGER_STATUS,
  OCPP_STATUS,
  SERVER_SIDE_CHARGER_STATUS,
} from '../constant/Text.constant';
import { ChargerList } from '../stores/types';
import {
  ChargerDetail,
  ChargerDetailPayload,
  ChargerSummaryRequest,
  ChargerSummaryResponse,
  ChargerWifiPassword,
  ClaimCharger,
  ClaimChargerRequest,
  DisplayIdAndOCCPValidationRequest,
  GetChargerDetailApiArg,
  MoveChargerPayload,
} from '../stores/types/chargers.interface';
import { Price } from '../stores/types/price.interface';
import { Charger, ChargerPort } from '../stores/types/sessions.interface';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE, providesListTag } from './utils';

export interface ChargerOptions {
  'filter_eq[locationId]'?: string;
  offset?: number;
  limit?: number;
  filter_hasTrouble?: boolean;
  includeAcl?: boolean; // include accessRules
  scope?: string;
  'filter_like[name]'?: string;
  companyId?: string;
  'filter_in[status]'?: string;
  'filter_eq[maintenanceFlag]'?: boolean;
  'filter_in[locationId]'?: string;
}

export interface AutoStartConfig {
  type: string; // SIMULATED, LOCAL, SIMULATED
  chargedTo?: string; // USER, COMPANY
  email?: string;
}

export interface UpdatedDeploymentInfo {
  deployedOverride?: boolean;
  deployedDateOverride?: string;
  installationDate?: string;
  installationPerson?: string;
  installationEmail?: string;
  connectivityType?: string;
  whitelabeledSimCard?: boolean;
  simCardType?: string;
  simCardProvider?: string;
  simCardId?: string;
  wifiName?: string;
  wifiPassword?: string;
}

export interface UpdateChargerInformation {
  id: string;
  directions?: string;
  parkingSpot?: string;
  associatedBuildingUnit?: string;
  description?: string;
  isFree?: boolean;
  internalNote?: string;
  autoStartConfig?: AutoStartConfig;
  chargerModelId: string;
  locationId: string;
  hiddenOnMap: boolean;
  displayId: string;
  priceModelId: string;
  deploymentInfo: UpdatedDeploymentInfo;
  maintenanceFlag: boolean;
  autoStartEnabled: boolean;
  ocppId: string;
  installationVoltage?: number;
  maxAmpsOverride?: number;
}

// Available: charger status is online and at least 1 port is available
// Charging: charger status is online and all ports are in an idle or charging state
// Coming Soon: charger status is coming soon
// Out of Order: charger status is out of order
// Offline: charger status is offline

const handleStatus = (ports: ChargerPort[]) => {
  if (ports.length === 1) {
    return ports?.every(
      (port: any) =>
        port?.rawChargerStatus?.rawChargerStatus === 'OUT_OF_ORDER' ||
        port.status === 'OUT_OF_ORDER',
    );
  }
  return ports?.every((port: any) => port.status === 'OUT_OF_ORDER');
};

const getChargerStatus = (status?: string, ports?: any) => {
  switch (status) {
    case SERVER_SIDE_CHARGER_STATUS.ONLINE: {
      return handleStatus(ports)
        ? CHARGER_STATUS.OUT_OF_ORDER
        : ports?.some((port: any) => port.status === 'AVAILABLE')
        ? CHARGER_STATUS.AVAILABLE
        : OCPP_STATUS.CHARGING;
    }
    case SERVER_SIDE_CHARGER_STATUS.COMING_SOON:
      return CHARGER_STATUS.COMING_SOON;
    case SERVER_SIDE_CHARGER_STATUS.OUT_OF_ORDER:
      return CHARGER_STATUS.OUT_OF_ORDER;
    case SERVER_SIDE_CHARGER_STATUS.CHARGING:
      return OCPP_STATUS.CHARGING;
    case SERVER_SIDE_CHARGER_STATUS.OFFLINE:
      return CHARGER_STATUS.OFFLINE;
    case SERVER_SIDE_CHARGER_STATUS.SCHEDULED:
      return CHARGER_STATUS.SCHEDULED;
    default:
      console.warn('unknown charger status:', status);
      return status;
  }
};

const formatChargerDetailsBody = (payload: ChargerDetailPayload) => {
  const requestBody: ChargerDetail[] = [];
  payload.chargerSpecificDetails.forEach((charger: ChargerDetail) => {
    const obj: any = {};
    Object.entries(payload.chargerSharedDetails)?.forEach(
      ([key, value]: [string, any]) => {
        switch (key) {
          case 'chargerManufacturerId':
            break;
          case 'locationName':
            break;
          case 'sharedConnectionType':
            break;
          case 'whitelabeledSimCard':
            obj[key] = value;
            break;
          case 'hiddenOnMap':
            obj[key] = value;
            break;
          default:
            if (value) {
              obj[key] = value;
            }
            break;
        }
      },
    );
    Object.entries(charger)?.forEach(([key, value]: [string, any]) => {
      if (value) {
        obj[key] = value;
      }
    });
    requestBody.push(obj);
  });
  return requestBody;
};

export const chargerApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchChargers: builder.query<ChargerList, ChargerOptions | undefined>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}`,
        params,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        providesListTag(result?.entities, API_KEYS.CHARGERS),
      transformResponse: (response: ChargerList) => ({
        totalCount: response.totalCount,
        entities: response.entities.map((charger: Charger) => {
          return {
            ...charger,
            status: getChargerStatus(charger.status, charger.ports),
          } as Charger;
        }),
      }),
    }),
    fetchChargersSummaryStatus: builder.query<
      ChargerSummaryResponse,
      ChargerSummaryRequest
    >({
      query: (param) => ({
        url: `/internal/core/v2/stats/${API_KEYS.CHARGERS}`,
        params: param,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        providesListTag(result?.siteHostOverviewStatus, API_KEYS.CHARGERS),
    }),
    fetchTroubleChargers: builder.query<{ troubleCount: number }, SCOPE_TYPE>({
      query: (scope) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}`,
        params: { limit: 0, filter_hasTrouble: true, scope },
        method: 'GET',
      }),
      transformResponse: (response: ChargerList) => ({
        troubleCount: response.totalCount,
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
      ],
    }),
    fetchChargerDetail: builder.query<Charger, GetChargerDetailApiArg>({
      query: (arg) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}/${arg.id}`,
        params: {
          scope: arg.scope,
        },
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: result?.id },
      ],
      transformResponse: (response: Charger) => {
        return {
          ...response,
          status: getChargerStatus(response.status, response.ports),
        } as Charger;
      },
    }),

    isValidCharger: builder.mutation<Charger, any>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}/${payload.id}`,
        params: payload.params,
        body: payload.body,
        method: 'PATCH',
      }),
    }),

    updateCharger: builder.mutation<
      Charger,
      {
        body: UpdateChargerInformation;
        params: { scope: SCOPE_TYPE; companyId?: string };
      }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}/${payload.body.id}`,
        params: payload.params,
        body: payload.body,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        { type: API_KEYS.CHARGERS, id: result?.id },
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
        { type: API_KEYS.LOCATION, id: 'LIST' },
        { type: API_KEYS.PAYMENT_TERMINALS_COMBINED, id: 'LIST' },
      ],
    }),
    validateDisplayIdAndOCPP: builder.mutation<
      any,
      { request: DisplayIdAndOCCPValidationRequest; scope: SCOPE_TYPE }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}/${payload.request.id}`,
        body: payload.request,
        params: { validateOnly: true, scope: payload.scope },
        method: 'PATCH',
      }),
    }),
    updateBulkChargers: builder.mutation<any, any>({
      query: (payload) => {
        return {
          url: `/internal/core/v2/${API_KEYS.CHARGER_DETAIL}`,
          body: payload.bulkOperation
            ? payload.bulkChargers
            : payload.bulkChargers[0],
          method: 'PATCH',
          params: {
            bulkOperation: payload.bulkOperation,
            autoPopulate: payload.autoPopulate,
          },
          hideToast: payload?.hideToast || false,
          toast: {
            successTrans: 'chargers_bulk_edit_success_toast',
            failedTrans: 'charger_bulk_edit_failed_toast',
            values: {
              chargerNumber: payload.bulkChargers.length,
            },
          },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        ...result
          .filter((item: any) => !item.error)
          .map((successItem: any) => ({
            type: API_KEYS.CHARGERS,
            id: successItem?.response.deviceId,
          })),
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
      ],
    }),
    rebootCharger: builder.mutation<
      any,
      { id: string; scope: SCOPE_TYPE; companyId: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}/${payload.id}/reset`,
        params: payload,
        body: { id: payload.id },
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        { type: API_KEYS.CHARGERS, id: arg.id },
        { type: API_KEYS.SESSIONS, id: 'LIST' },
      ],
    }),
    addCharger: builder.mutation<any, ChargerDetailPayload>({
      query: (payload: ChargerDetailPayload) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGER_DETAIL}`,
        body: payload.bulkOperation
          ? formatChargerDetailsBody(payload)
          : formatChargerDetailsBody(payload)[0],
        method: 'POST',
        params: { bulkOperation: payload.bulkOperation },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
        { type: API_KEYS.LOCATION, id: 'LIST' },
        { type: API_KEYS.PAYMENT_TERMINALS_COMBINED, id: 'LIST' },
      ],
    }),
    deleteCharger: builder.mutation<
      any,
      { id: string; name: string; scope: SCOPE_TYPE; companyId: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}/${payload.id}`,
        params: { scope: payload.scope, companyId: payload.companyId },
        method: 'DELETE',
        toast: {
          successTrans: 'charger_delete_success_toast_message',
          failedTrans: 'charger_delete_error_toast_message',
          values: {
            chargerName: payload.name,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        { type: API_KEYS.LOCATION, id: 'LIST' },
        { type: API_KEYS.PAYMENT_TERMINALS_COMBINED, id: 'LIST' },
      ],
    }),
    claimCharger: builder.mutation<any, ClaimChargerRequest>({
      query: (payload: ClaimChargerRequest) => ({
        url: `/internal/core/v2/${API_KEYS.PROVISION}/claim`,
        body: payload.bulkOperation ? payload.chargers : payload.chargers[0],
        method: 'POST',
        params: { bulkOperation: payload.bulkOperation },
      }),
    }),
    claimValidate: builder.mutation<any, ClaimCharger>({
      query: (payload: ClaimCharger) => ({
        url: `/internal/core/v2/${API_KEYS.PROVISION}/claim:validate`,
        body: payload,
        method: 'POST',
      }),
    }),
    fetchWifiPassword: builder.query<
      ChargerWifiPassword,
      { id: string; scope: SCOPE_TYPE }
    >({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.CHARGERS}/${payload.id}/wifiPassword`,
        params: { scope: payload.scope },
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: arg.id },
      ],
    }),
    moveCharger: builder.mutation<any, MoveChargerPayload>({
      query: (data: MoveChargerPayload) => ({
        url: `/internal/core/v2/${API_KEYS.PROVISION}/transfer`,
        body: data.payload,
        method: 'POST',
        toast: {
          successTrans: 'move_charger_success_toast',
          failedTrans: 'move_charger_failed_toast',
          values: data.toast,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.CHARGERS, id: 'LIST' },
        { type: API_KEYS.PRICE_MODELS, id: 'LIST' },
        { type: API_KEYS.CHARGERS_BY_BATCH, id: 'LIST' },
        { type: API_KEYS.LOCATION, id: 'LIST' },
        { type: API_KEYS.PAYMENT_TERMINALS_COMBINED, id: 'LIST' },
      ],
    }),
    fetchPricingByCompanyId: builder.query<Price[], any>({
      query: (companyId: string | undefined) => ({
        url: `/internal/core/v2/${API_KEYS.PRICE_MODELS}`,
        method: 'GET',
        params: { companyId },
        needCompanyId: false,
      }),
    }),
    fetchSparkAnalyze: builder.query<any, any>({
      query: ({ filters, chargerId }) => ({
        url: `/internal/core/v3/${API_KEYS.CHARGERS}/${chargerId}/spark-analyze`,
        method: 'GET',
        params: filters,
      }),
    }),
  }),
});

export const {
  useLazyFetchChargersSummaryStatusQuery,
  useFetchPricingByCompanyIdQuery,
  useLazyFetchPricingByCompanyIdQuery,
  useFetchChargersQuery,
  useLazyFetchChargersQuery,
  useFetchTroubleChargersQuery,
  useFetchChargerDetailQuery,
  useLazyFetchChargerDetailQuery,
  useIsValidChargerMutation,
  useUpdateChargerMutation,
  useRebootChargerMutation,
  useAddChargerMutation,
  useDeleteChargerMutation,
  useUpdateBulkChargersMutation,
  useClaimChargerMutation,
  useClaimValidateMutation,
  useFetchWifiPasswordQuery,
  useMoveChargerMutation,
  useValidateDisplayIdAndOCPPMutation,
  useLazyFetchSparkAnalyzeQuery,
} = chargerApi;
