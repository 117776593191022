import { useTranslation } from 'react-i18next';
import { ChargerEV, CheckMark, Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { ColorType, Icon, Label, LabelType } from '../../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';

enum PMIncompatibleReason {
  FW_NOT_APPROVED = 'FW_NOT_APPROVED',
  MODEL_NOT_COMPATIBLE = 'MODEL_NOT_COMPATIBLE',
}

interface Props {
  chargerName: string;
  showTopBorder?: boolean;
  chargerModel: string;
  chargerVendor: string;
  chargerFirmware?: string;
  inCompatibleCharger: boolean;
  pmIncompatibleReason: string;
}

const ViewChargerItem = ({
  chargerName,
  showTopBorder = false,
  chargerModel,
  chargerVendor,
  chargerFirmware,
  inCompatibleCharger,
  pmIncompatibleReason,
}: Props) => {
  const { t } = useTranslation();

  const getTooltipText = () => {
    if (pmIncompatibleReason === PMIncompatibleReason.MODEL_NOT_COMPATIBLE) {
      return t('pm_charger_model_not_supported');
    }
    if (pmIncompatibleReason === PMIncompatibleReason.FW_NOT_APPROVED) {
      return t('pm_unverified_firmware');
    }
    return t('pm_charger_not_connected');
  };

  // var MAKE_MODEL needs to be replaced with origin field getting from api.
  return (
    <div
      className='px-4 py-2'
      style={{
        borderBottom: '1px solid #D1D6DB',
        borderTop: showTopBorder ? '1px solid #D1D6DB' : '',
      }}
    >
      <div className='flex flex-col gap-0'>
        <div className='flex flex-start'>
          <div className='flex items-center'>
            <div className='pr-2 pt-1'>
              <Icon src={ChargerEV} alt='charger' isTranslationOn />
            </div>
            <div className='flex flex-row w-60'>
              <Label
                text={chargerName}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.BLACK}
                className='truncate'
              />
            </div>
          </div>
          <div className='flex items-center gap-2 px-6'>
            {inCompatibleCharger ? (
              <Icon
                src={CheckMark}
                size={IconSize.SIZE_16x16}
                color={ColorType.POSITIVE1}
              />
            ) : (
              <Tooltip placement='top'>
                <TooltipTrigger>
                  <Icon src={Info} size={IconSize.SIZE_16x16} />
                </TooltipTrigger>
                <TooltipContent>{getTooltipText()}</TooltipContent>
              </Tooltip>
            )}
            <Label
              text={`${chargerVendor} ${chargerModel}`}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.GREY6}
              className='truncate'
            />
            <Label text='/' type={LabelType.LABEL_M} color={ColorType.GREY4} />
            {chargerFirmware ? (
              <Label
                text={chargerFirmware}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.GREY5}
              />
            ) : (
              <Label
                text={t('pm_unknown_firmware')}
                type={LabelType.LABEL_S_MEDIUM}
                color={ColorType.GREY4}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewChargerItem;
