/* eslint-disable no-restricted-globals */
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CancelClose, ErrorWarningCircle } from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import {
  formatCardNumber,
  formatChargelabAcceptedCharacters,
  formatPercent,
} from '../Vehicles/utils';
import { Icon } from './Icon.component';
import { Label, LabelType } from './Label.component';
import { ColorType } from './index';
import {
  isValidURL,
  validateCurrency,
  validateDisplayId,
  validateDomainName,
  validateEmail,
  validateIdleFees,
  validateOcppId,
} from './modal/utils';

export enum ValidationType {
  NONE,
  NOT_EMPTY,
  NUMBER,
  POSITIVE_NUMBER,
  EMAIL,
  HOURS_12,
  MINS,
  CURRENCY,
  CURRENCY_ZERO_ALLOWED,
  DOMAIN_NAME,
  GEO_LAT,
  GEO_LON,
  PERCENT,
  DURATION,
  IDLE_FEE,
  DISPLAY_ID,
  OCPP_ID,
  LINK,
}

export enum InputType {
  TEXT,
  NUMBER,
  FLOAT_NUMBER,
  VIN, // IN Input can only accept alphanumeric characters O, I, and Q are never present Special characters should not be enterable
  RFID,
  DATE,
  PERCENT,
  GEOLOCATION,
  DOMAIN,
  /**
   * https://chargelab.atlassian.net/wiki/spaces/PROD/pages/1575452675/Style+guide#Accepted-characters
   * Upper and lowercase A-Z
   * All numbers: 0-9
   * Special characters: ! @ # $ & + - ( ) ? , . : ; " '/
   */
  CHARGELAB_ACCEPTED_CHARACTER,
  CURRENCY_INPUT,
}

interface InputProps {
  label?: string;
  labelType?: LabelType;
  labelTypeColor?: ColorType;
  secondLabel?: string;
  secondLabelType?: LabelType;
  secondLabelColor?: ColorType;
  icon?: any;
  iconAlt?: string;
  iconSize?: { width: number; height: number };
  iconColor?: ColorType;
  suffixIcon?: any;
  suffixIconClassName?: string;
  sufficIconSize?: { width: number; height: number };
  errorLabel?: string;
  props?: any;
  placeholder?: string;
  onChange?: Function;
  validationType?: ValidationType;
  onValidation?: Function;
  showErrorIcon?: boolean;
  defaultValue?: any | undefined;
  inputType?: InputType;
  minLen?: string;
  maxLen?: string;
  showFoundResult?: boolean;
  foundResult?: any;
  onCancelClick?: Function;
  iconClassName?: string;
  inputIconPadding?: string;
  validaitonQueryResult?: boolean;
  onClickIcon?: Function;
  width?: string;
  height?: string;
  showOutline?: boolean;
  disabled?: boolean;
  onFocus?: Function;
  onBlur?: Function;
  onClickSuffix?: Function;
  inputId?: string;
  wrapperClassName?: string;
  // TODO: This should be a mandatory field going forward for automated test
  // Keeping it optional until we write all the test
  dataTestId?: string;
  companyCurrency?: string;
  options?: Array<any> | undefined;
  onEnterKeyPress?: Function;
  invalidErrorClass?: string;
  inputClass?: string;
  inputTitle?: string;
}

export const FormInput = ({
  width = 'max-content',
  height = '40px',
  label = '',
  labelType = LabelType.LABEL_M,
  labelTypeColor = ColorType.BLACK,
  secondLabel = '',
  secondLabelType = LabelType.LABEL_M_MEDIUM,
  secondLabelColor = ColorType.GREY6,
  errorLabel: error = '',
  icon,
  iconAlt = '',
  suffixIcon,
  suffixIconClassName = '',
  placeholder = '',
  onChange = () => null,
  validationType = ValidationType.NONE,
  onValidation,
  showErrorIcon = false,
  defaultValue = '',
  inputType = InputType.TEXT,
  minLen,
  maxLen = '255',
  props,
  showFoundResult = false,
  foundResult,
  onCancelClick,
  iconClassName = 'pl-2',
  inputIconPadding = 'pl-7',
  iconSize = IconSize.SIZE_16x16,
  iconColor,
  sufficIconSize = IconSize.SIZE_16x16,
  validaitonQueryResult,
  onClickIcon,
  showOutline = true,
  disabled = false,
  onFocus = () => {},
  onBlur = () => {},
  onClickSuffix = () => null,
  inputId = '',
  wrapperClassName = '',
  dataTestId,
  companyCurrency,
  options = [],
  onEnterKeyPress,
  invalidErrorClass = 'focus:outline-negative border-2 border-negative',

  inputClass = '',
  inputTitle = '',
}: InputProps) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const [isValid, setIsValid] = useState(true);

  const getIsValid = (val: any) => {
    if (minLen && val.length < Number(minLen)) {
      return false;
    }

    if (validaitonQueryResult) {
      return false;
    }
    if (onValidation) {
      return onValidation(val);
    }

    if (validationType) {
      switch (validationType) {
        case ValidationType.GEO_LAT:
          return Number(val) >= -90 && Number(val) <= 90;
        case ValidationType.GEO_LON:
          return Number(val) >= -180 && Number(val) <= 180;
        case ValidationType.DURATION:
          return !_.isEmpty(val) && Number(val) > 0;
        case ValidationType.NOT_EMPTY:
          return !_.isEmpty(val);
        case ValidationType.NUMBER:
          return !_.isEmpty(val) && !isNaN(val);
        case ValidationType.PERCENT:
          return val > 0 && val <= 100;
        case ValidationType.POSITIVE_NUMBER:
          return !_.isEmpty(val) && !isNaN(val) && Number(val) > 0;
        case ValidationType.EMAIL:
          return validateEmail(val);
        case ValidationType.HOURS_12:
          return Number.isInteger(Number(val)) && val >= 1 && val <= 12;
        case ValidationType.MINS:
          if (Number(val) === 0) {
            return val === '0' || val === '00';
          }
          return Number.isInteger(Number(val)) && val >= 0 && val < 60;
        case ValidationType.CURRENCY: {
          return validateCurrency(val);
        }
        case ValidationType.CURRENCY_ZERO_ALLOWED: {
          return validateCurrency(val, false);
        }
        case ValidationType.IDLE_FEE: {
          return validateIdleFees(val, companyCurrency, false);
        }
        case ValidationType.DOMAIN_NAME: {
          return validateDomainName(val);
        }
        case ValidationType.DISPLAY_ID: {
          return validateDisplayId(val);
        }
        case ValidationType.OCPP_ID: {
          return validateOcppId(val);
        }
        case ValidationType.LINK: {
          return isValidURL(val);
        }
        default:
          return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!_.isEmpty(value) || validaitonQueryResult) {
      setIsValid(getIsValid(value));
    }
  }, [value, validaitonQueryResult]);

  useEffect(() => {
    if (validaitonQueryResult !== undefined && !_.isEmpty(value)) {
      setIsValid(!validaitonQueryResult);
    }
  }, [validaitonQueryResult]);

  const formatInputCurrency = (inputValue: any) => {
    // Null or empty check
    if (inputValue === null || inputValue.trim() === '') {
      return '';
    }

    if (inputValue.startsWith('.')) {
      inputValue = `0${inputValue}`;
    }

    // Ensure only two decimal places
    if (inputValue.includes('.')) {
      const parts = inputValue.split('.');
      if (parts[1].length > 2) {
        return `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
    }
    return inputValue;
  };

  const handleInputChange = (event: any) => {
    if (!event.target.validity.valid) {
      return;
    }
    let newValue = event.target.value;
    switch (inputType) {
      case InputType.PERCENT:
        newValue = formatPercent(event.target.value);
        break;
      case InputType.RFID:
        newValue = formatCardNumber(event.target.value);
        break;
      case InputType.DOMAIN:
        if (event.target.value.includes('@')) break;
        newValue = event.target.value;
        break;
      case InputType.CHARGELAB_ACCEPTED_CHARACTER:
        newValue = formatChargelabAcceptedCharacters(event.target.value);
        break;
      case InputType.CURRENCY_INPUT:
        newValue = formatInputCurrency(event.target.value);
        break;
      default:
        newValue = event.target.value;
        break;
    }
    setIsValid(getIsValid(newValue));
    setValue(newValue);
    onChange &&
      onChange({
        ...event,
        target: {
          ...event.target,
          value: newValue,
        },
      });
  };

  const inputPattern = useMemo(() => {
    switch (inputType) {
      case InputType.GEOLOCATION:
        return '[+|-]?[0-9.]*';
      case InputType.NUMBER:
        return '[0-9]+([,.][0-9]+)?';
      case InputType.FLOAT_NUMBER:
      case InputType.PERCENT:
      case InputType.CURRENCY_INPUT:
        return '[0-9.]*';
      case InputType.VIN:
        return '^(?:(?![OIQoiq])[a-zA-Z0-9])+$';
      case InputType.RFID:
        return '[a-zA-Z0-9 ]*';
      case InputType.DATE:
        return '[0-9/]*';
      default:
        return undefined;
    }
  }, [inputType]);

  const handleOnClick = () => {
    onClickIcon && onClickIcon();
  };

  return (
    <div className='block' style={{ width }}>
      {label && (
        <div className='flex flex-row pb-2 gap-2 items-baseline'>
          <Label
            type={labelType}
            color={labelTypeColor}
            text={label}
            labelHtmlFor={inputId}
          />
          {secondLabel && (
            <Label
              type={secondLabelType}
              color={secondLabelColor}
              text={secondLabel}
            />
          )}
        </div>
      )}

      <div className={`relative ${wrapperClassName}`}>
        {icon && (
          <span
            className={`absolute inset-y-0 left-0 flex items-center ${iconClassName}`}
          >
            <Icon
              src={icon}
              onClick={handleOnClick}
              size={iconSize}
              color={iconColor}
              alt={iconAlt}
            />
          </span>
        )}
        <input
          pattern={inputPattern}
          id={inputId}
          placeholder={placeholder}
          autoComplete='off'
          className={`
          placeholder:text-grey4 pt-2.5 pb-2.5 rounded text-sm font-inter text-black bg-grey1 hover:bg-grey2 ${inputClass}
          ${suffixIcon ? 'pr-7' : 'pr-3'}
          ${
            showOutline
              ? isValid
                ? 'focus:outline-black'
                : invalidErrorClass
              : 'focus:outline-none'
          }
          ${icon ? inputIconPadding : 'pl-3'}
           `}
          onChange={handleInputChange}
          value={value}
          maxLength={maxLen}
          style={{ width, height }}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid={dataTestId}
          onKeyDown={onEnterKeyPress}
          title={inputTitle}
          aria-label={inputId}
          {...props}
        />
        {suffixIcon && !disabled && (
          <span
            className={`absolute inset-y-0  ${
              !isValid && showErrorIcon
                ? 'right-3 flex items-center mr-[30px]'
                : 'right-0 flex items-center mr-[10px]'
            } ${suffixIconClassName}`}
          >
            <Icon
              size={sufficIconSize}
              key={suffixIcon}
              src={suffixIcon}
              onClick={onClickSuffix}
            />
          </span>
        )}
        {!isValid && showErrorIcon && (
          <span className='absolute inset-y-0 right-0 flex items-center mr-[15px]'>
            <Icon
              src={ErrorWarningCircle}
              size={IconSize.SIZE_20x20}
              alt='icon_alt_error'
              isTranslationOn
            />
          </span>
        )}
        {showFoundResult && (
          <div className='absolute items-center right-1 top-2.5 opacity-50'>
            <div className='flex flex-row items-center gap-4'>
              <div className='text-sm text-grey5 font-normal'>
                {foundResult}
              </div>
              <Label
                type={LabelType.LABEL_M}
                text=''
                icon={CancelClose}
                onClick={onCancelClick}
              />
            </div>
          </div>
        )}
      </div>
      {!isValid && error && (
        <Label
          type={LabelType.BODY3}
          color={ColorType.NEGATIVE}
          className='pt-1 block'
          text={error}
        />
      )}
    </div>
  );
};
