import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Analyze, ErrorWarningtraiangle } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { NUMBER } from '../../../constant/Number.constant';
import { useCompany } from '../../../hooks/useCompany';
import { useSurvey } from '../../../hooks/useSurvey';
import { useLazyFetchSparkAnalyzeQuery } from '../../../services/charger.api';
import { Charger } from '../../../stores/types';
import { formatIso, getLastOneHrs } from '../../../utils/Date.Util';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../../_ui';
import {
  CustomDatePicker,
  DATE_PICKER_TYPE,
  DATE_RANGE,
} from '../../_ui/date-picker/Custom-Date-Picker.component';
import { formatSparkResult } from '../utils';
import { SparkLoading } from './SparkLoading.component';
import { SparkResults } from './SparkResults.compoent';

type SparkAnalyzeProps = {
  isModelView: boolean;
  charger: Charger;
  isResultLoading?: boolean;
  setIsResultLoading?: any;
  setSparkResultData?: any;
  selectedDates?: any;
  isSparkLimitExceed?: boolean;
};

// eslint-disable-next-line react/display-name
export const SparkAnalyze = memo(
  ({
    isModelView,
    charger,
    isResultLoading,
    setIsResultLoading,
    setSparkResultData,
    selectedDates = {
      selDates: getLastOneHrs(),
      selOption: DATE_RANGE.LAST_ONE_HOUR,
    },
    isSparkLimitExceed = false,
  }: SparkAnalyzeProps) => {
    const { companyId, companyScope } = useCompany();
    const [dateTimeRange, setDateTimeRange] = useState(selectedDates);
    const { t } = useTranslation();
    const { showModal, hideModal } = useGlobalModalContext();
    const { LAST_ONE_HOUR, LAST_THREE_HOUR, CUSTOM_RANGE } = DATE_RANGE;
    const [isRecordCountOverSparkLimit, setIsRecordCountOverSparkLimit] =
      useState(isSparkLimitExceed);

    const [triggerFetchSparkAnalyze, { data, isFetching, isError }] =
      useLazyFetchSparkAnalyzeQuery();
    const { triggerSurveyByEvent } = useSurvey();

    const onCloseModal = () => {
      triggerSurveyByEvent('spark-customer-satisfaction-survey');
      hideModal();
    };

    const handleSparkResultModel = (resultData: any) => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('spark_result_model_header'),
        width: '540px',
        height: 'max-content',
        onRenderBody: () => (
          <SparkResults
            charger={charger}
            resultData={resultData}
            dateTimeRange={dateTimeRange}
            isSparkLimitExceed={isRecordCountOverSparkLimit}
          />
        ),
        onIconClick: onCloseModal,
        shouldCloseOnOverlayClick: false,
      });
    };

    const showLoadingAlert = () => {
      setIsRecordCountOverSparkLimit(false);
      const [startTime, endTime] = dateTimeRange.selDates;
      triggerFetchSparkAnalyze({
        filters: {
          chargerId: charger.id,
          'filter[receivedTimeUtc][gte]': formatIso(startTime),
          'filter[receivedTimeUtc][lte]': formatIso(endTime),
          sort: '-receivedTimeUtc',
          limit: NUMBER.ONE_THOUSAND, // min-max limit 50-1000 ocpp logs
          scope: companyScope,
          companyId: charger?.ownerId || companyId,
          timezone: charger?.location?.timeZone,
        },
        chargerId: charger.id,
      });
      if (!isModelView) {
        showModal(MODAL_TYPES.SPINNER_MODAL, {
          spinnerComponent: <SparkLoading />,
          width: '400px',
          height: '359px',
        });
      }
    };

    const isValidDateRange = useMemo(() => {
      // validate future date and time
      return (
        dateTimeRange.selDates.length === 2 &&
        (dateTimeRange.selDates[0] > new Date() ||
          dateTimeRange.selDates[1] > new Date())
      );
    }, [dateTimeRange.selDates]);

    useEffect(() => {
      if (data) {
        const formatData = formatSparkResult(data.content);
        setIsRecordCountOverSparkLimit(data?.isRecordCountOverSparkLimit);

        if (!isModelView) {
          handleSparkResultModel({
            ...formatData,
            copyToClipboardContent: data.content,
          });
        } else {
          setSparkResultData({
            ...formatData,
            copyToClipboardContent: data.content,
          });
          setIsResultLoading(false);
        }
      }
    }, [data]);

    useEffect(() => {
      if (isModelView && isFetching) {
        setIsResultLoading(true);
        setSparkResultData(null);
      }

      if (isError) {
        hideModal();
      }
    }, [isFetching, isError, isModelView]);

    return (
      <div className='flex flex-col gap-4'>
        <div className='flex flex-row items-center gap-8'>
          {isModelView && (
            <Label
              text={t('spark_time_range_label')}
              type={LabelType.LABEL_S}
              className='min-w-[80px]'
            />
          )}
          <div className='flex flex-col gap-4' data-html2canvas-ignore='true'>
            <div className='flex gap-2'>
              <CustomDatePicker
                pickerType={DATE_PICKER_TYPE.DATE_TIME_PICKER}
                optionList={[LAST_ONE_HOUR, LAST_THREE_HOUR, CUSTOM_RANGE]}
                format='MMM d,yyyy'
                onChange={(option: any, range: any) => {
                  setIsRecordCountOverSparkLimit(false);
                  setDateTimeRange({ selOption: option, selDates: range });
                }}
                selectedOption={dateTimeRange.selOption}
                selectedDate={dateTimeRange.selDates}
                showHighligtHeader={false}
              />
              <Button
                label={t('spark_analyze_btn')}
                labelIconSize={IconSize.SIZE_16x16}
                type={ButtonType.PRIMARY}
                icon={Analyze}
                size={ButtonSize.SMALL}
                onClick={showLoadingAlert}
                disabled={isValidDateRange || isResultLoading}
              />
            </div>
          </div>
        </div>
        {isValidDateRange && (
          <div className='p-2 flex flex-row gap-2 border-2 border-warning bg-warning0 rounded-lg'>
            <Icon
              src={ErrorWarningtraiangle}
              size={IconSize.SIZE_24x24}
              color={ColorType.WARNING}
            />
            <Label
              text={t('spark_future_date_warning')}
              type={LabelType.BODY4}
              color={ColorType.GREY6}
            />
          </div>
        )}
        {isValidDateRange && (
          <div className='p-2 flex flex-row gap-2 border-2 border-warning bg-warning0 rounded-lg'>
            <Icon
              src={ErrorWarningtraiangle}
              size={IconSize.SIZE_24x24}
              color={ColorType.WARNING}
            />
            <Label
              text={t('spark_future_date_warning')}
              type={LabelType.BODY4}
              color={ColorType.GREY6}
            />
          </div>
        )}
        {isModelView && isRecordCountOverSparkLimit && (
          <div className='p-2 flex flex-row gap-2 border-2 border-warning bg-warning0 rounded-lg'>
            <Icon
              src={ErrorWarningtraiangle}
              size={IconSize.SIZE_24x24}
              color={ColorType.WARNING}
            />
            <div className='flex flex-col gap-0'>
              <Label
                text={t('spark_limit_exceed_warning_title')}
                type={LabelType.BODY4_BOLD}
                color={ColorType.GREY6}
              />
              <Label
                text={t('spark_limit_exceed_warning_content')}
                type={LabelType.BODY4}
                color={ColorType.GREY6}
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);
