import { useTranslation } from 'react-i18next';
import { useAuth, USER_ROLE } from '../../../hooks';
import { Charger } from '../../../stores/types';
import { formatDate, parseDate } from '../../../utils/Date.Util';
import {
  ColorType,
  DateTimePicker,
  FormInput,
  Label,
  LabelType,
  Pill,
  Skeleton,
  Switch,
} from '../../_ui';
import { CustomInputType } from '../../_ui/date-picker/Date-Picker.component';
import { InputType, ValidationType } from '../../_ui/Input.component';

type ChargerDeploymentProps = {
  charger: Charger;
  editEnabled: boolean;
  isLoading: boolean;
  handleInputChange: any;
  updateChargerDetail: any;
};
export const ChargerDeployment = ({
  charger,
  editEnabled,
  isLoading,
  handleInputChange,
  updateChargerDetail,
}: ChargerDeploymentProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  return (
    <div className='flex flex-col gap-0 pt-4'>
      <Label
        text={t('charger_deployment')}
        type={LabelType.LABEL_M}
        color={ColorType.GREY5}
        style={{ minHeight: '40px' }}
      />
      <div className={`flex flex-col ${editEnabled ? 'gap-1' : 'gap-0'}`}>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_deployment_status')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Pill
              label={
                charger?.deploymentInfo?.deployed
                  ? t('charger_deployment_status_true')
                  : t('charger_deployment_status_false')
              }
              bgColor={
                charger?.deploymentInfo?.deployed
                  ? ColorType.POSITIVE
                  : ColorType.GREY2
              }
              labelColor={
                charger?.deploymentInfo?.deployed
                  ? ColorType.WHITE
                  : ColorType.GREY6
              }
              isLoading={isLoading}
            />
          )}
          {editEnabled && auth.role === USER_ROLE.NORMAL && (
            <Pill
              label={
                charger?.deploymentInfo?.deployed
                  ? t('charger_deployment_status_true')
                  : t('charger_deployment_status_false')
              }
              bgColor={
                charger?.deploymentInfo?.deployed
                  ? ColorType.POSITIVE
                  : ColorType.GREY4
              }
              labelColor={ColorType.WHITE}
            />
          )}
          {/* Only support role should be allowed to deploy/un-deploy charger */}
          {editEnabled && auth.role === USER_ROLE.SUPPORT && (
            <Switch
              value={updateChargerDetail.deploymentInfo?.deployedOverride}
              enableLabel={t('charger_deployment_status_true')}
              disableLabel={t('charger_deployment_status_false')}
              onChange={(checked: boolean) => {
                handleInputChange('deploymentInfo', {
                  ...updateChargerDetail.deploymentInfo,
                  deployedOverride: checked,
                });
              }}
            />
          )}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          {(!editEnabled || charger?.deploymentInfo?.deployedDate) && (
            <Label
              text={t('charger_deployment_date')}
              type={LabelType.LABEL_S}
              color={ColorType.GREY6}
              className='min-w-[148px] max-w-[148px] mr-2.5'
            />
          )}
          {!editEnabled && isLoading && <Skeleton height='h-5' width='w-52' />}
          {!editEnabled &&
            !isLoading &&
            updateChargerDetail.deploymentInfo?.deployedOverride && (
              <Label
                text={formatDate(
                  parseDate(
                    charger?.deploymentInfo?.deployedDate
                      ? charger?.deploymentInfo?.deployedDate
                      : '',
                    'yyyy-MM-dd',
                  ),
                )}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
              />
            )}
          {editEnabled &&
            updateChargerDetail.deploymentInfo?.deployedOverride && (
              <Label
                text={formatDate(
                  parseDate(
                    charger?.deploymentInfo?.deployedDate
                      ? charger?.deploymentInfo?.deployedDate
                      : '',
                    'yyyy-MM-dd',
                  ),
                )}
                type={LabelType.BODY3}
                color={ColorType.BLACK}
              />
            )}
          {/* Only support role should be allowed to make change to deployment date */}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('chargers_installer')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Label
              text={charger?.deploymentInfo?.installationPerson}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isLoading}
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <div className='flex-1'>
              <FormInput
                defaultValue={
                  updateChargerDetail.deploymentInfo?.installationPerson
                }
                onChange={(event: any) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    installationPerson: event.target.value,
                  });
                }}
                width='100%'
                height='36px'
              />
            </div>
          )}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_installer_email')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Label
              text={charger?.deploymentInfo?.installerContactEmail}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isLoading}
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <div className='flex-1'>
              <FormInput
                defaultValue={
                  updateChargerDetail.deploymentInfo?.installationEmail
                }
                onChange={(event: any) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    installationEmail: event.target.value,
                  });
                }}
                width='100%'
                height='36px'
                inputType={InputType.TEXT}
                validationType={ValidationType.EMAIL}
                showErrorIcon
                errorLabel={t('chargers_email_validation_message')}
              />
            </div>
          )}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('chargers_installation_date')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {!editEnabled && (
            <Label
              text={formatDate(
                parseDate(
                  charger?.deploymentInfo?.installationDate
                    ? charger?.deploymentInfo?.installationDate
                    : '',
                  'yyyy-MM-dd',
                ),
              )}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
              isLoading={isLoading}
              skeletonWidth='w-52'
              skeletonHeight='h-5'
            />
          )}
          {editEnabled && (
            <div className='flex-1'>
              <DateTimePicker
                onChange={(date) => {
                  handleInputChange('deploymentInfo', {
                    ...updateChargerDetail.deploymentInfo,
                    installationDate: formatDate(date, 'yyyy-MM-dd'),
                  });
                }}
                format='MM/dd/yyyy'
                customInputType={CustomInputType.FORMINPUTDATE}
                customFormInputDivWidth='100%'
                showDefaultDate={
                  !!updateChargerDetail.deploymentInfo?.installationDate
                }
                defaultDate={
                  updateChargerDetail.deploymentInfo?.installationDate
                    ? parseDate(
                        updateChargerDetail.deploymentInfo?.installationDate ||
                          '',
                        'yyyy-MM-dd',
                      )
                    : new Date()
                }
                customFormInputErrorLabel={t(
                  'chargers_date_validation_message',
                )}
              />
            </div>
          )}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_deployment_added_by')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          {isLoading && <Pill isLoading={isLoading} />}
          {!isLoading && charger?.deploymentInfo?.addedBy && (
            <Pill
              label={charger?.deploymentInfo?.addedBy}
              bgColor={ColorType.GREY2}
              labelColor={ColorType.GREY6}
            />
          )}
        </div>
        <div className='flex flex-row items-start min-h-[40px]'>
          <Label
            text={t('charger_deployment_date_added')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='min-w-[148px] max-w-[148px] mr-2.5'
          />
          <Label
            text={formatDate(
              parseDate(
                charger?.deploymentInfo?.addedDate
                  ? charger?.deploymentInfo?.addedDate
                  : '',
                'yyyy-MM-dd',
              ),
            )}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            isLoading={isLoading}
            skeletonWidth='w-52'
            skeletonHeight='h-5'
          />
        </div>
      </div>
    </div>
  );
};
