import { memo } from 'react';
import { ButtonType } from './Button.component';
import { Icon } from './Icon.component';
import { Skeleton } from './Skeleton/Skeleton.component';
import { ColorType, isHexColor } from './index';

export enum LabelType {
  H1 = 'text-[2rem] leading-10 font-semibold font-inter' /* font-size: 32px, line-height: 40px, font-weight: 600 */,
  H2 = 'text-[1.75rem] leading-9 font-semibold font-inter' /* font-size: 28px, line-height: 36px, font-weight: 600 */,
  H3 = 'text-2xl font-semibold font-inter' /* font-size: 24px, line-height: 32px, font-weight: 600 */,
  H4 = 'text-xl font-semibold font-inter' /* font-size: 20px, line-height: 28px, font-weight: 600 */,
  H5 = 'text-[1.125rem] leading-[1.625rem] font-semibold font-inter' /* font-size: 18px, line-height: 26px, font-weight: 600 */,
  BODY1 = 'text-lg font-normal font-inter' /* font-size: 18px, line-height: 28px, font-weight: 400 */,
  BODY2 = 'text-base font-normal font-inter' /* font-size: 16px, line-height: 24px, font-weight: 400 */,
  BODY3 = 'text-sm font-normal font-inter' /* font-size: 14px, line-height: 20px, font-weight: 400 */,
  BODY4 = 'text-[0.75rem] leading-5 font-normal font-inter' /* font-size: 12px, line-height: 20px, font-weight: 400 */,
  BODY4_BOLD = 'text-[0.75rem] leading-5 font-bold font-inter' /* font-size: 12px, line-height: 20px, font-weight: 700 */,
  LABEL_L = 'text-lg font-semibold font-inter' /* font-size: 18px, line-height: 28px, font-weight: 600 */,
  LABEL_M = 'text-base font-semibold font-inter' /* font-size: 16px, line-height: 24px, font-weight: 600 */,
  LABEL_M_MEDIUM = 'text-base font-medium font-inter' /* font-size: 16px, line-height: 24px, font-weight: 500 */,
  LABEL_S = 'text-sm font-semibold font-inter' /* font-size: 14px, line-height: 20px, font-weight: 600 */,
  LABEL_S_MEDIUM = 'text-sm font-medium font-inter' /* font-size: 14px, line-height: 20px, font-weight: 500 */,
  LABEL_XS = 'text-xs font-semibold font-inter' /* font-size: 12px, line-height: 16px, font-weight: 600 */,
  LABEL_XS_MEDIUM = 'text-xs font-medium font-inter' /* font-size: 12px, line-height: 16px, font-weight: 500 */,
  LABEL_XXS = 'text-[0.625rem] leading-3 font-semibold font-inter' /* font-size: 10px, line-height: 12px, font-weight: 600 */,
  LABEL_XXS_MEDIUM = 'text-[0.625rem] leading-3 font-medium font-inter' /* font-size: 10px, line-height: 12px, font-weight: 500 */,

  /** Remaining work for button type  */
  DROPDOWN_HEADER = 'text-sm font-inter font-medium text-black',
  DROPDOWN_HEADER_SELECTED = 'text-sm font-inter font-medium text-white',
  DROPDOWN_ITEM_SELECTED = 'text-sm font-inter font-normal',
  BODY3_G4 = 'text-sm font-inter text-grey4 font-normal', // Dropdown
  LABEL_S_GREY4 = 'text-sm font-inter font-medium text-grey4', // Dropdown
  BODY3_DISABLED = 'text-sm font-inter font-normal text-grey3', // Dropdown
  SESSION_DROPDOWN_HEADER = 'text-sm font-inter font-medium text-grey6',

  // Works for tab
  ACTIVE_TAB = 'text-xl font-inter font-semibold text-black ease-in-out', // Tabs
  INACTIVE_TAB = 'text-base font-inter font-semibold text-grey4 ease-in-out',
}

interface InputProps {
  type: LabelType | ButtonType;
  color?: ColorType | string;
  text?: any;
  className?: string;
  id?: string;
  icon?: any;
  iconClass?: string;
  svgIcon?: any;
  svgColor?: string;
  style?: any;
  onClick?: Function;
  isLoading?: boolean;
  skeletonWidth?: string;
  skeletonHeight?: string;
  skeletonLoadingColor?: string;
  htmlText?: any;
  dataTestId?: string;
  lazyIcon?: boolean;
  labelHtmlFor?: string;
  htmlFor?: string;
}

export const Label = memo(
  ({
    type,
    color = ColorType.BLACK,
    text,
    className = '',
    iconClass = '',
    id = '',
    icon = null,
    svgIcon,
    svgColor,
    style = {},
    onClick,
    isLoading = false,
    skeletonWidth = 'w-40',
    skeletonHeight = 'h-4',
    skeletonLoadingColor = 'bg-grey2',
    htmlText,
    dataTestId = '',
    lazyIcon,
    labelHtmlFor = undefined,
  }: InputProps) => {
    if (isLoading) {
      return (
        <Skeleton
          className={`${className}`}
          height={skeletonHeight}
          width={skeletonWidth}
          loadingColor={skeletonLoadingColor}
        />
      );
    }

    const isHexC = isHexColor(color);
    const styles = isHexC ? { ...style, color } : style;

    const getLabelElement = (labelType: LabelType | ButtonType) => {
      if (labelHtmlFor) return 'label';
      switch (labelType) {
        case LabelType.H1:
          return 'h1';
        case LabelType.H2:
          return 'h2';
        case LabelType.H3:
          return 'h3';
        case LabelType.H4:
          return 'h4';
        case LabelType.H5:
          return 'h5';
        case LabelType.BODY1:
        case LabelType.BODY2:
        case LabelType.BODY3:
        case LabelType.BODY4:
        case LabelType.BODY3_G4:
        case LabelType.BODY3_DISABLED:
          return 'p'; // Use <p> for body text
        case LabelType.LABEL_L:
        case LabelType.LABEL_M:
        case LabelType.LABEL_M_MEDIUM:
        case LabelType.LABEL_S:
        case LabelType.LABEL_S_MEDIUM:
        case LabelType.LABEL_XS:
        case LabelType.LABEL_XS_MEDIUM:
        case LabelType.LABEL_XXS:
        case LabelType.LABEL_XXS_MEDIUM:
        case LabelType.LABEL_S_GREY4:
          return 'div';
        default:
          return 'div'; // Default to `div` for non-heading labels
      }
    };

    const Tag = getLabelElement(type);

    const htmlFormLabel = labelHtmlFor ? { htmlFor: labelHtmlFor } : {};

    return (
      <Tag
        {...htmlFormLabel}
        id={id}
        style={styles}
        className={`outline-0 border-0 ${type} ${
          !isHexC && `text-${color}`
        }  ${className} ${icon || svgIcon ? 'flex items-center' : ''} ${
          onClick && 'cursor-pointer'
        }`}
        onClick={() => {
          onClick && onClick();
        }}
        data-testid={dataTestId}
      >
        {icon && (
          <Icon
            src={icon}
            className={`mr-2 ${iconClass}`}
            lazyLoad={lazyIcon}
          />
        )}
        {svgIcon && <Icon src={svgIcon} color={svgColor} className='mr-2' />}
        {text && text}
        {htmlText && (
          <span
            dangerouslySetInnerHTML={{
              __html: htmlText,
            }}
          />
        )}
      </Tag>
    );
  },
);

// Label.displayName fixes eslint error Component missing display name.
Label.displayName = 'Label';
